/* TOPNAV */
.top-nav-logo {
    width: 130px;
    height: auto;
}

.top-nav-dropdown-name:hover,
.show > .btn-success.dropdown-toggle,
.top-nav-dropdown-name {
    background: transparent !important;
    color: #4d4d4d !important;
    border: none;
    display: flex;
    align-items: center;
}

.top-nav-dropdown.dropdown-menu {
    max-height: unset !important;
    min-width: unset;
    width: unset !important;
}

.border-top-nav {
    border-bottom: 2px solid rgba(109, 109, 109, 0.1);
    height: 80px;
    background-color: #fff;
    position: relative;
    z-index: 500;
}

/**/
.border-right-nav {
    height: calc(100vh - 80px);
    border-right: 2px solid rgba(109, 109, 109, 0.1);
    /* box-shadow: 2px 0px 5px rgba(109, 109, 109, 0.1); */
}

.right-nav-col {
    width: 100%;
    border: none;
    background: transparent;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    color: #6d6d6d;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
}

.right-nav-col::before {
    position: absolute;
    content: "";
    bottom: 0;
    width: calc(100% - 48px);
    height: 1px;
    background: rgba(109, 109, 109, 0.3);
}

.right-nav-col-minimized::before {
    width: calc(100% - 32px) !important;
}

.right-nav-col:hover span,
.right-nav-col:hover svg path,
.right-nav-col:hover svg polyline,
.right-nav-col:hover svg line,
.right-nav-col:hover svg circle {
    color: #fd5744;
}

.no-hover:hover svg path {
    color: #6d6d6d !important;
}

.right-nav-col.active span,
.right-nav-col-custom.active span,
.right-nav-col.active svg path,
.right-nav-col.active svg polyline,
.right-nav-col.active svg line,
.right-nav-col.active svg circle {
    color: #fd5744;
}

.icon-nav-size {
    font-size: 24px;
}

.icon-box-size {
    font-size: 64px;
}

.settings-icon {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #000;
    font-size: 12px;
}

.settings-icon:hover {
    color: #fd5744;
}

/**/
.dashboard-bg {
    height: calc(100vh - 80px);
    overflow-y: auto;
    background-color: #fbfbfb;
}

.min-height-tab-amazon {
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
}

.min-height-tab {
    min-height: calc(100vh - 160px);
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
}

.min-height-tab-charge {
    max-height: 510px;
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
}

.max-h-190px {
    max-height: 190px;
}

.min-height-tab-tenant {
    max-height: 510px;
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
    height: auto;
}

.min-height-tab-without-tab {
    min-height: calc(100vh - 155px);
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
}

.border-top {
    border-top: 1px solid rgba(109, 109, 109, 0.5) !important;
}

.nav-tabs .nav-link.active {
    border-color: rgba(109, 109, 109, 0.5) rgba(109, 109, 109, 0.5) #fff;
}

.nav-tabs {
    border-bottom: 1px solid rgba(109, 109, 109, 0.5);
}

.total-statistic-block {
    border: 1px solid rgba(109, 109, 109, 0.5);
}

.total-statistic-col {
    border: 1px solid rgba(109, 109, 109, 0.5);
    flex: 0 0 auto;
    height: 180px;
    width: 24%;
    border-radius: 3px;
}

.total-statistic-header {
    color: #4d4d4d;
    text-align: start;
    font-size: 48px;
    width: 100%;
}

.font-size-12 {
    font-size: 14px;
}

.font-size-12px {
    font-size: 12px;
}

.color-FD5744 {
    color: #fd5744;
}

.width-fit-content {
    width: fit-content;
}

.total-statistic-cubes {
    font-size: 108px;
}

.total-statistic-cubes path {
    color: #4d4d4d !important;
}

.max-width {
    max-width: 100%;
}

.admin-nav-bar {
    width: 15%;
    max-width: 15%;
    min-width: 260px;
    transition: all 0.2s ease-out;
    overflow-x: hidden;
}

.admin-nav-bar.minimized {
    width: 5%;
    max-width: 5%;
    min-width: 120px;
}

.admin-minimized-nav-bar-tooltip {
    z-index: 200;
}

.admin-minimized-nav-bar-tooltip > .tooltip-inner {
    min-width: unset !important;
    max-width: unset !important;
    padding: 12px 24px !important;
    text-wrap: nowrap !important;
    box-shadow: #63636333 0px 2px 8px 0px !important;
    font-weight: 500;
}

.portal-nav-bar {
    width: 10%;
    max-width: 10%;
    min-width: 235px;
}

/**/

.window {
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    width: 50%;
    height: max-content;
    min-height: 350px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    background-color: #fff;
}

.incoming-product {
    width: 96%;
}

.send-to-amazon {
    min-height: 250px !important;
}

.btn-link {
    color: #fff !important;
}

.window-product-parameters {
    width: 80%;
    min-width: 600px;
}

.link-invoice {
    color: #fff !important;
}

.window-set-up {
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    width: 40%;
    height: max-content;
    min-height: 350px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    background-color: #fff;
}

.fz-14 {
    font-size: 14px;
}

.button-full-width {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 100% !important;
    font-size: 12px !important;
}

.accordion-item {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    border: none;
    margin-bottom: 1%;
}

.acordeon-button-container {
    display: flex;
    justify-content: center;
}

.accordion-content {
    padding-top: 1%;
}

.box-width {
    position: absolute;
    width: 100px;
    top: 10px;
    right: calc(50% - 220px);
    font-size: 16px;
}

.box-height {
    width: 100px;
    position: absolute;
    top: 150px;
    left: calc(50% - 270px);
    font-size: 16px;
}

.box-length {
    width: 100px;
    position: absolute;
    bottom: 20px;
    right: calc(50% - 150px);
    font-size: 16px;
}

.carton-box {
    width: 400px;
    height: auto;
    margin: auto;
    object-fit: contain;
}

.p-relative {
    position: relative;
}

.mouse-parallax_lend1 {
    width: 650px;
    height: 600px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -60px;
    top: -43px;
}

.mouse-parallax_lend2 {
    width: 580px;
    height: 700px;
    position: absolute;
    bottom: 205px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lend-2 {
    height: 600px;
    width: 717px;
}

.header-mouse-parallax {
    z-index: 1;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    background: #56c1ff;
}

.header-full {
    height: calc(100vh + 130px) !important;
}

@keyframes animX {
    0% {
        left: 234px;
    }

    100% {
        left: calc(100% - 300px);
    }
}

@keyframes animY {
    0% {
        top: 207px;
    }

    100% {
        top: calc(100vh - 300px);
    }
}

.ship {
    width: 160px;
}

.wove {
    width: 40px;
    top: 6px;
    position: absolute;
    left: 18px;
    transform: rotate(331deg);
    animation: 1400ms wove 3500ms ease-in-out;
    animation-iteration-count: 5;
    opacity: 0;
}

@keyframes wove {
    0% {
        left: 18px;
        top: 6px;
        opacity: 0.7;
    }

    100% {
        left: -40px;
        top: 40px;
        opacity: 1;
    }
}

.wove1 {
    width: 40px;
    top: 16px;
    position: absolute;
    left: -11px;
    transform: rotate(331deg);
    animation: 1400ms wove1 3500ms ease-in-out;
    animation-iteration-count: 5;
    opacity: 0;
}

@keyframes wove1 {
    0% {
        left: -11px;
        top: 16px;
        opacity: 0.7;
    }

    100% {
        left: -71px;
        top: 31px;
        opacity: 1;
    }
}

.wove2 {
    width: 40px;
    top: -14px;
    position: absolute;
    left: 19px;
    transform: rotate(378deg);
    animation: 1400ms wove2 3500ms ease-in-out;
    animation-iteration-count: 5;
    opacity: 0;
}

@keyframes wove2 {
    0% {
        left: 19px;
        top: -14px;
        opacity: 0.7;
    }

    100% {
        left: -23px;
        top: -50px;
        opacity: 1;
    }
}

.wove3 {
    width: 40px;
    top: -4px;
    position: absolute;
    left: 9px;
    transform: rotate(378deg);
    animation: 1400ms wove3 3500ms ease-in-out;
    animation-iteration-count: 5;
    opacity: 0;
}

@keyframes wove3 {
    0% {
        left: 9px;
        top: -4px;
        opacity: 0.7;
    }

    100% {
        left: -62px;
        top: -43px;
        opacity: 1;
    }
}

:root {
    --duration: 12000ms;
}

.simple-title-herosection {
    font-size: 24px;
}

.mover {
    fill: none;
    stroke-width: 5px;
    stroke: #000;
    --anchorX: 100%;
    --anchorY: 100%;
    top: 130px;
    left: 630px;
    z-index: 100;
    transition: all 0.5s ease-in-out;

    position: absolute;
    offset-path: path("M 0 0");
    offset-rotate: 0deg;
    offset-distance: 100%;
}

.mover-anim {
    animation: move-along var(--duration) ease-in;
}

@keyframes move-along {
    0% {
        offset-distance: 0%;
    }

    10% {
        offset-distance: 0%;
    }

    90% {
        offset-distance: 100%;
    }
}

.mover::before,
.sku-drop::before,
.amazon::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "Fill containers with fellow SKUDroppers to get the best shipping rate.";
    position: absolute;
    top: 0;
    left: 50%;
    width: 120px;
    height: 120px;
    background-color: #fff;
    text-align: center;
    line-height: 16px;
    padding: 4px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateX(-50%) !important;
    font-weight: bold;
    font-size: 14px;
    border-radius: 7px;
}

.mover::after,
.sku-drop::after,
.amazon::after {
    content: "";
    position: absolute;
    top: 120px;
    left: 50%;
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateX(-50%) !important;
    border: 10px solid transparent;
    border-top: 15px solid #fff;
}

.amazon::after {
    top: 60px;
}

.mover:hover::before,
.sku-drop:hover::before,
.amazon:hover::before,
.mover:hover::after,
.sku-drop:hover::after,
.amazon:hover::after {
    opacity: 1;
}

.mover::before {
    top: -119px;
}

.mover::after {
    top: 0;
}

.sku-drop::before {
    transform: rotate(0deg);
    width: 150px !important;
    left: 250px;
    top: 45px;
}

.amazon::before {
    transform: rotate(0deg);
}

.sku-drop::before {
    content: "Store, prep & ship \a smaller regular \a quantities straight \a into Amazon.";
    white-space: pre;
}

.amazon::before {
    content: "Ship directly \a into Amazon, \a eliminate the use \a of 3PL's & MAKE \a MORE PROFIT.";
    white-space: pre;
    top: -60px;
}

.sku-drop::after {
    border: 15px solid transparent;
    border-right: 15px solid #fff;
    top: 90px;
    left: 160px;
}

.plnew,
.amazon,
.sku-drop {
    position: absolute;
    right: 65px;
    width: 460px;
    bottom: 250px;
}

.amazon {
    bottom: 450px;
    left: 110px;
    width: 440px;
}

.plnew {
    right: 100px;
    bottom: 200px;
    width: 250px;
}

.sku-drop img,
.build-img,
.amazon img {
    width: 100%;
    height: auto;
}

.water {
    position: absolute;
    z-index: -1;
    top: -95px;
    left: -159px;
}

.water2 {
    top: -70px;
    left: -320px;
    position: absolute;
    z-index: -1;
}

.cartons-in {
    position: absolute;
    width: 90px;
    height: auto;
    top: 190px;
    right: 20px;
    opacity: 0;
    --anchorX: 100%;
    --anchorY: 100%;
    z-index: 100;
    transition: all 0.5s ease-in-out;

    offset-path: path("M 0 0");
    offset-distance: 100%;
    offset-rotate: 0deg;
    offset-path: path("M 0 10 Q 95 -240 185 -130");
}

.cartons-in-anim {
    animation: move-cartons 1500ms ease-in-out;
}

@keyframes move-cartons {
    0% {
        offset-distance: 0%;
        opacity: 1;
        width: 90px;
    }

    99% {
        opacity: 1;
    }

    100% {
        offset-distance: 100%;
        opacity: 0;
        width: 45px;
    }
}

.cartons-out {
    width: 45px;
    height: auto;
    bottom: 530px;
    right: 500px;
    opacity: 0;
    --anchorX: 100%;
    --anchorY: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    position: absolute;
    offset-path: path("M 0 0");
    offset-distance: 100%;
    offset-rotate: 0deg;
    offset-path: path("M 0 0 Q 190 -300 240 -135");
}

.cartons-out-anim {
    animation: 1.5s move-cartons2 11000ms ease-in-out;
}

@keyframes move-cartons2 {
    0% {
        offset-distance: 0%;
        opacity: 1;
        width: 45px;
    }

    99% {
        opacity: 1;
    }

    100% {
        offset-distance: 100%;
        opacity: 0;
        width: 35px;
    }
}

.cartons-out img {
    width: 100%;
    height: auto;
}

.water-circle {
    position: absolute;
    left: -50px;
    bottom: -15px;
    left: 14px;
    opacity: 1;
    width: 130px;
    transform: rotate(0deg) !important;
    z-index: -1;
}

@keyframes water-circle {
    0% {
        width: 100px;
        left: 0;
        bottom: 0px;
        opacity: 1;
    }

    99% {
        opacity: 1;
    }

    100% {
        width: 130px;
        left: -15px;
        bottom: -10px;
        opacity: 0;
    }
}

.error3pl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    opacity: 0;
}

.error3pl-anim {
    animation: 1.5s error3pl 11800ms ease-in-out;
}

@keyframes error3pl {
    0% {
        width: 155px;
        opacity: 1;
    }

    25% {
        width: 180px;
    }

    50% {
        width: 155px;
    }

    75% {
        width: 180px;
    }

    99% {
        opacity: 1;
    }

    100% {
        width: 155px;
        opacity: 0;
    }
}

.text-white h1,
.text-white h5 {
    color: #fff !important;
}

.wave-header {
    width: 100%;
    position: absolute;
    bottom: -20px !important;
    left: 0;
    z-index: 1000;
    bottom: 50px;
    right: calc(50% - 200px);
    font-size: 16px;
}

.width-30 {
    width: 30% !important;
}

.configuration-text {
    font-weight: 600;
    padding: 0px 20px 0 0px;
}

.configuration-text div {
    font-weight: 300;
}

.modal-height .modal-dialog {
    margin: 50vh auto;
    transform: translateY(-50%) !important;
}

.confirm-tracking-modal-body {
    max-height: 650px;
    height: 100%;
}

@media screen and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .mouse-parallax_lend1 {
        width: 600px;
        height: 580px;
    }

    .lend-1 {
        width: auto;
        height: 390px;
    }

    .lend-2 {
        height: 550px;
        width: 550px;
    }

    .mouse-parallax_lend2 {
        height: 600px;
        width: 515px;
    }

    .plnew,
    .amazon,
    .sku-drop {
        right: 15px;
        width: 470px;
        bottom: 230px;
    }

    .amazon {
        bottom: 380px !important;
        width: 370px;
        left: 100px;
    }

    .plnew {
        right: 60px;
        bottom: 160px;
        width: 255px;
    }

    .cartons-in {
        position: absolute;
        width: 75px;
        height: auto;
        top: 220px;
        right: 60px;
        opacity: 0;
        --anchorX: 100%;
        --anchorY: 100%;
        z-index: 100;
        transition: all 0.5s ease-in-out;
        offset-path: path("M 0 0");
        offset-distance: 0;
        offset-rotate: 0deg;
        offset-path: path("M 0 10 Q 95 -240 165 -190");
    }

    .cartons-in-anim {
        animation: move-cartons 1500ms ease-in-out;
    }

    @keyframes move-cartons {
        0% {
            offset-distance: 0%;
            opacity: 1;
            width: 75px;
        }

        99% {
            opacity: 1;
        }

        100% {
            offset-distance: 100%;
            opacity: 0;
            width: 45px;
        }
    }

    .cartons-out {
        width: 50px;
        height: auto;
        bottom: 490px;
        right: 445px;
        opacity: 0;
        --anchorX: 100%;
        --anchorY: 100%;
        z-index: 99;
        transition: all 0.5s ease-in-out;
        position: absolute;
        offset-path: path("M 0 0");
        offset-distance: 0;
        offset-rotate: 0deg;
        offset-path: path("M 0 0 Q 80 -200 205 -95");
    }

    .cartons-out-anim {
        animation: 1.5s move-cartons2 11000ms ease-in-out;
    }

    @keyframes move-cartons2 {
        0% {
            offset-distance: 0%;
            opacity: 1;
            width: 50px;
        }

        99% {
            opacity: 1;
        }

        100% {
            offset-distance: 100%;
            opacity: 0;
            width: 30px;
        }
    }

    .cartons-out img {
        width: 100%;
        height: auto;
    }

    .mover {
        fill: none;
        stroke-width: 5px;
        stroke: #000;
        --anchorX: 100%;
        --anchorY: 100%;
        top: 90px;
        left: 570px;
        z-index: 100;
        transition: all 0.5s ease-in-out;
        position: absolute;
        offset-path: path("M 0 0");
        offset-rotate: 0deg;
        offset-distance: 100%;
    }

    .mover-anim {
        animation: move-along var(--duration) ease-in;
    }

    @keyframes move-along {
        0% {
            offset-distance: 0%;
        }

        10% {
            offset-distance: 0%;
        }

        90% {
            offset-distance: 100%;
        }
    }

    .ship {
        width: 130px;
    }

    .water-circle {
        position: absolute;
        left: -50px;
        bottom: -12px;
        left: 14px;
        opacity: 1;
        width: 100px;
    }

    .first-block-lending-z h1 {
        font-size: 2.2rem !important;
    }

    .simple-title-herosection {
        font-size: 20px !important;
    }
}

@media screen and (max-device-width: 1399px) and (-webkit-min-device-pixel-ratio: 1) {
    .mouse-parallax_lend1 {
        width: 400px;
        height: 400px;
    }

    .lend-2 {
        height: 400px;
        width: 400px;
    }

    .lend-1 {
        width: 630px;
        height: auto;
    }

    .mouse-parallax_lend2 {
        width: 400px;
        height: 460px;
    }

    .plnew,
    .amazon,
    .sku-drop {
        right: -15px;
        width: 300px;
        bottom: 160px;
    }

    .amazon {
        bottom: 295px !important;
        left: 85px;
    }

    .plnew {
        right: 50px;
        bottom: 120px;
        width: 215px;
    }

    .cartons-in {
        position: absolute;
        width: 60px;
        height: auto;
        top: 130px;
        right: 30px;
        opacity: 0;
        --anchorX: 100%;
        --anchorY: 100%;
        z-index: 100;
        transition: all 0.5s ease-in-out;

        offset-path: path("M 0 0");
        offset-distance: 100%;
        offset-rotate: 0deg;
        offset-path: path("M 0 10 Q 95 -240 215 -85");
    }

    .cartons-in-anim {
        animation: move-cartons 1500ms ease-in-out;
    }

    @keyframes move-cartons {
        0% {
            offset-distance: 0%;
            opacity: 1;
            width: 60px;
        }

        99% {
            opacity: 1;
        }

        100% {
            offset-distance: 100%;
            opacity: 0;
            width: 35px;
        }
    }

    .cartons-out {
        width: 30px;
        height: auto;
        bottom: 430px;
        right: 355px;
        opacity: 0;
        --anchorX: 100%;
        --anchorY: 100%;
        z-index: 99;
        transition: all 0.5s ease-in-out;
        position: absolute;
        offset-path: path("M 0 0");
        offset-distance: 100%;
        offset-rotate: 0deg;
        offset-path: path("M 0 0 Q 80 -200 192 -70");
    }

    .cartons-out-anim {
        animation: 1.5s move-cartons2 11000ms ease-in-out;
    }

    @keyframes move-cartons2 {
        0% {
            offset-distance: 0%;
            opacity: 1;
            width: 45px;
        }

        99% {
            opacity: 1;
        }

        100% {
            offset-distance: 100%;
            opacity: 0;
            width: 30px;
        }
    }

    .cartons-out img {
        width: 100%;
        height: auto;
    }

    .mover {
        fill: none;
        stroke-width: 5px;
        stroke: #000;
        --anchorX: 100%;
        --anchorY: 100%;
        top: 90px;
        left: 510px;
        z-index: 100;
        transition: all 0.5s ease-in-out;
        position: absolute;
        offset-path: path("M 0 0");
        offset-rotate: 0deg;
        offset-distance: 100%;
    }

    .mover-anim {
        animation: move-along var(--duration) ease-in;
    }

    @keyframes move-along {
        0% {
            offset-distance: 0%;
        }

        10% {
            offset-distance: 0%;
        }

        90% {
            offset-distance: 100%;
        }
    }

    .ship {
        width: 110px;
    }

    .water-circle {
        position: absolute;
        left: -50px;
        bottom: -11px;
        left: 9px;
        opacity: 1;
        width: 90px;
    }

    .first-block-lending-z h1 {
        font-size: 2.2rem !important;
    }

    .simple-title-herosection {
        font-size: 20px !important;
    }

    .mouse-parallax_lend1 {
        width: 400px;
        height: 400px;
    }

    .lend-2 {
        height: 400px;
        width: 400px;
    }

    .mouse-parallax_lend2 {
        width: 400px;
        height: 460px;
    }

    .plnew,
    .amazon,
    .sku-drop {
        right: -15px;
        width: 300px;
        bottom: 160px;
    }

    .amazon {
        bottom: 295px !important;
        left: 85px;
    }

    .plnew {
        right: 50px;
        bottom: 120px;
        width: 215px;
    }

    .cartons-in {
        position: absolute;
        width: 60px;
        height: auto;
        top: 130px;
        right: 30px;
        opacity: 0;
        --anchorX: 100%;
        --anchorY: 100%;
        z-index: 100;
        transition: all 0.5s ease-in-out;

        offset-path: path("M 0 0");
        offset-distance: 100%;
        offset-rotate: 0deg;
        offset-path: path("M 0 10 Q 95 -240 215 -85");
    }

    .cartons-in-anim {
        animation: move-cartons 1500ms ease-in-out;
    }

    @keyframes move-cartons {
        0% {
            offset-distance: 0%;
            opacity: 1;
            width: 60px;
        }

        99% {
            opacity: 1;
        }

        100% {
            offset-distance: 100%;
            opacity: 0;
            width: 35px;
        }
    }

    .cartons-out {
        width: 30px;
        height: auto;
        bottom: 430px;
        right: 355px;
        opacity: 0;
        --anchorX: 100%;
        --anchorY: 100%;
        z-index: 99;
        transition: all 0.5s ease-in-out;
        position: absolute;
        offset-path: path("M 0 0");
        offset-distance: 100%;
        offset-rotate: 0deg;
        offset-path: path("M 0 0 Q 80 -200 192 -70");
    }

    .cartons-out-anim {
        animation: 1.5s move-cartons2 11000ms ease-in-out;
    }

    @keyframes move-cartons2 {
        0% {
            offset-distance: 0%;
            opacity: 1;
            width: 45px;
        }

        99% {
            opacity: 1;
        }

        100% {
            offset-distance: 100%;
            opacity: 0;
            width: 30px;
        }
    }

    .cartons-out img {
        width: 100%;
        height: auto;
    }

    .mover {
        fill: none;
        stroke-width: 5px;
        stroke: #000;
        --anchorX: 100%;
        --anchorY: 100%;
        top: 90px;
        left: 510px;
        z-index: 100;
        transition: all 0.5s ease-in-out;
        position: absolute;
        offset-path: path("M 0 0");
        offset-rotate: 0deg;
        offset-distance: 100%;
    }

    .mover-anim {
        animation: move-along var(--duration) ease-in;
    }

    @keyframes move-along {
        0% {
            offset-distance: 0%;
        }

        10% {
            offset-distance: 0%;
        }

        90% {
            offset-distance: 100%;
        }
    }

    .ship {
        width: 110px;
    }

    .water-circle {
        position: absolute;
        left: -50px;
        bottom: -11px;
        left: 9px;
        opacity: 1;
        width: 90px;
    }

    .first-block-lending-z h1 {
        font-size: 2.2rem !important;
    }

    .simple-title-herosection {
        font-size: 20px !important;
    }
}

.sticky-search {
    position: sticky;
    top: 0px;
    background-color: #fff;
    height: 58px;
    z-index: 99;
}

.sticky-header-table {
    position: sticky;
    top: 58px;
    background-color: #fff;
    z-index: 100;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: #fff;
}

.sticky-bottom {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.add-prod-modal {
    max-height: 40vh;
    overflow-y: auto;
}

.min-width-nav {
    min-width: 300px !important;
    max-width: 300px !important;
}

.min-width-dashboard {
    max-width: calc(100%);
}

.col-1 {
    flex: 0 0 auto;
    width: 20%;
}

.col-10 {
    flex: 0 0 auto;
    width: 80%;
}

.dropdown-item:focus {
    background-color: #fd5744;
}

.max-height-table {
    max-height: 65vh;
    overflow-y: auto;
}

.order-to-prep {
    color: #91cf57;
    font-size: 45px !important;
    margin-right: 5px !important;
}

.row-hover-table:hover td {
    color: #fff;
    cursor: pointer;
    background-color: #fd796b;
}

.row-hover-table.uploaded-products:hover td .country-flag svg {
    color: white !important;
}

.no-bg:hover td {
    cursor: pointer;
    background-color: none !important;
}

.product-name-truncate {
    overflow: hidden;
    max-width: 120px;
    min-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.check-row {
    width: auto;
}

.switch {
    position: relative !important;
    top: 80% !important;
    right: 85% !important;
}

.checkbox-cubic-meeters {
    right: 37%;
    width: fit-content;
    transform: translate(50%, 0);
}

.checkbox-coobick-feet {
    margin-top: 5px;
    font-size: 8px;
    white-space: nowrap;
}

.form-switch .form-check-input {
    margin-left: -35px !important;
    color: #fd5744 !important;
}

.font-size-small {
    font-size: 10px !important;
}

.onOff:checked::before {
    left: 22px;
}

.before-add-product-container {
    flex-wrap: wrap;
}

.before-add-product-box {
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    width: 35%;
    margin-top: 20px;
    max-width: 380px;
    min-width: 320px;
}

.add-product-tub {
    max-height: calc(100vh - 160px);
}

.window-for-amazon-product {
    border-radius: 0.5rem;
    width: auto;
    min-height: 350px;
    justify-content: space-between;
    padding: 25px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    background-color: #fff;
    height: calc(100vh - 190px);
    position: relative;
}

.add-product-table-container {
    max-height: calc(100vh - 410px);
    overflow-y: auto;
}

.max-h-calc-error {
    max-height: calc(100vh - 470px);
}

.check-control {
    margin-top: -1px;
    padding: 0.45rem !important;
}

.margin-right-1 {
    margin-right: 0.25rem !important;
}

.margin-right-2 {
    margin-right: 0.5rem !important;
}

.margin-right-3 {
    margin-right: 1.25rem !important;
}

.margin-left-1 {
    margin-left: 0.25rem !important;
}

.width-10 {
    width: 11% !important;
}

.width-13 {
    width: 13% !important;
}

.width-15 {
    width: 15% !important;
}

.width-20 {
    width: 20% !important;
}

.width-9 {
    width: 9% !important;
}

.auto {
    width: auto;
}

.table-scroll-amazon-product {
    overflow: auto;
    display: block;
    table-layout: auto;
}

.sticky-header-table-add-product {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 100;
}

.pointer {
    cursor: pointer;
}

.waiting {
    padding: 8px 20px !important;
}

.padding-x {
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.py-05 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.1rem !important;
}

.ml-1 {
    margin-left: 0.75rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.mr-1 {
    margin-right: 0.75rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.top-30 {
    margin-top: 20% !important;
}

.new-product-true {
    position: absolute;
    margin-top: -30px !important;
    z-index: 1;
}

.new-product-text {
    margin-top: -0.18rem !important;
    background-color: #e74c3c;
    border: none !important;
    border-radius: 5px;
    padding: 6px 6px !important;
    font-size: 10px !important;
    font-family: "AvenirLTStd-Black" !important;
    color: #ffff !important;
    outline: none !important;
    text-transform: uppercase;
    padding-top: 0.3rem !important;
    padding-bottom: 0.2rem !important;
    line-height: normal !important;
}

.product-warning-icon {
    font-size: 20px !important;
    font-family: "AvenirLTStd-Black" !important;
    margin-left: 20%;
    color: #e74c3c;
}

.point {
    font-size: 10px !important;
    padding: 2px;
    margin-right: 2px;
}

.reasons_modal {
    display: flex;
    flex-direction: column;
    max-height: 650px;
    overflow-y: auto;
    gap: 8px;
}

.help-email {
    text-decoration: underline;
    color: rgb(0, 133, 185);
    cursor: pointer;
}

.reason-table-container {
    max-height: 300px;
    overflow-y: auto;
}

.select-language {
    width: auto;
    border: none !important;
    text-align: right;
    padding-left: 25px !important;
    color: #4d4d4d !important;
}

.select-filter {
    width: auto;
    background-color: #fff !important;
    border: none;
    color: #4d4d4d !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.form-select {
    font-size: 1rem !important;
}

.font-size-14i {
    font-size: 14px !important;
}

.warningBlock {
    font-size: 100px;
    text-align: center;
}

.mw-600 {
    max-width: 600px !important;
}

.modWidh {
    width: 70%;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.role-heading {
    margin-left: -17px;
}

.filter-date {
    background-color: rgba(0, 0, 0, 0.05);
    width: auto !important;
    border-radius: 15px;
}

.bell-container {
    position: relative;
}

.bell-container svg {
    color: rgb(77, 77, 77);
    cursor: pointer;
}

.bell-container svg:hover {
    color: #fd5744;
}

.alert-dropdown {
    width: 450px;
    height: 65vh;
    position: absolute;
    right: -100px;
    top: 40px;
    background-color: #fff;
    z-index: 1001;
    border: 1px solid rgba(109, 109, 109, 0.1);
    border-radius: 4px;
}

.alert-header {
    padding: 10px;
    margin: 5px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(109, 109, 109, 0.1);
}

.alert-header svg {
    color: #4d4d4d;
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.alert-header svg:hover {
    color: #fd5744;
}

.alert-body {
    height: 90%;
    overflow-y: auto;
}

.notification {
    padding: 15px;
    border-bottom: 1px solid rgba(109, 109, 109, 0.1);
    position: relative;
}

.notification-icon-container svg {
    color: #ed220b;
    width: 24px;
    height: 24px;
}

.new-alert {
    width: 17px;
    height: 17px;
    background-color: #ed220b;
    color: #fff !important;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 10px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unread-notification {
    background-color: #56c1ff;
    height: 10px;
    width: 10px;
    border-radius: 5px;
}

.unread {
    background-color: #cae9fb;
}

.notification-text-container {
    width: 85%;
}

.notification-date {
    color: rgb(124, 124, 124);
}

.input {
    border-color: #e20338 !important;
}

.read-more {
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    color: #e74c3c;
}

.unchacked {
    background-color: #ffe7e4;
}

.plus-fee-icon {
    width: 30px;
    height: 30px;
    margin-top: 74px;
    position: absolute;
    right: 21%;
}

.w-15 {
    width: 15% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-95 {
    width: 95% !important;
}

.w-54 {
    width: 54% !important;
}

.w-65px {
    width: 65px !important;
}

.remaining-text {
    font-weight: bold;
    color: #fd5744;
}

.no-partial_delivery {
    font-weight: normal;
}

.partial_delivery {
    color: #fd5744;
}

.pt-6px {
    padding-top: 6px !important;
}

.arrow-boat {
    width: 24%;
    height: 25px;
}

.title-min-width {
    width: auto;
}

.min-boat-width {
    min-width: max-content;
}

.custom-margin-right {
    margin-right: -20px;
}

.custom-margin-left {
    margin-left: -20px;
}

.success-icon {
    width: 100px;
    height: 100px;
}

.max-width-750 {
    max-width: 750px;
}

.total-incoming-title {
    margin-top: -8px !important;
}

.margin-past-shipments {
    margin-top: 3%;
}

.or-title {
    border-top: 1px solid #4d4d4d;
    height: 1px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.or-title h5 {
    margin-bottom: 0;
    background-color: #fff;
    width: min-content;
}

.video-js {
    width: 99% !important;
    height: 10vw !important;
}

.small-button {
    width: 65px !important;
    height: 20px !important;
    padding: 0 !important;
    padding-top: 3px !important;
}

.fix-width {
    width: 125px !important;
    padding-top: 6px !important;
}

.video-block {
    border: 1px solid #d8d8d8;
    width: 100%;
    min-height: 150px;
}

.file-upload-block {
    border: 1px solid #d8d8d8;
    width: 200px !important;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.hover-download {
    position: absolute;
    top: 40px;
    left: -15px;
    min-width: 250px;
    border-radius: 5px;
    z-index: 10;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tooltip-inner {
    background-color: #fff;
}

.incoming-cartons-factory-tooltip > .tooltip-inner {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    min-width: 250px;
    max-width: 300px;
    font-weight: 500;
    opacity: 1;
}

.tooltip.show {
    opacity: 1;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #fbfbfb;
}

.tooltip1-inner {
    background-color: #fbfbfb;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    min-width: 200px !important;
    font-weight: 500;
    opacity: 1;
    color: #000;
}

.tooltip1.show {
    opacity: 1;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    border-left-color: #fbfbfb !important;
    border-right-color: #fbfbfb !important;
}

.text-truncate-container {
    width: 120px;
}

.text-truncate-4-line {
    display: -webkit-box;
    max-width: 120px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-truncate-1-line {
    display: -webkit-box;
    max-width: 120px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.mt-4px {
    margin-top: 4px !important;
}

.user-communication-container {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    padding: 30px 45px;
    margin-bottom: 20px;
    background-color: #fff;
}

.user-communication-label {
    font-weight: 600;
    color: #4d4d4d;
    font-size: 1.1rem;
}

.choose-recipients-group {
    display: flex;
    align-items: center;
    width: 100%;
}

.select-all-recipients-block {
    display: flex;
}

.user-communication-send-block {
    display: flex;
    justify-content: end;
    padding: 0;
}

.communication-banner-input {
    padding: 20px 0;
}

.communication-notification-input {
    padding: 30px 0;
}

.communication-email-input {
    height: 350px;
    padding: 20px 0;
    resize: none;
    white-space: pre-line;
}

.user-communication-input-group {
    position: relative;
}

.limit-characters {
    position: absolute;
    bottom: 3px;
    right: 10px;
}

.communication-character-limit {
    color: #ff5744;
}

.time-picker-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 332px;
    background-color: rgb(239, 242, 247);
    padding: 10px;
}

.time-picker-input {
    width: 100%;
    text-align: center;
    outline: none;
    padding: 5px;
    border-radius: 4px;
    font-family: "AvenirLTStd-Roman";
    font-size: 0.75rem;
    color: #7d888d;
    box-shadow: 0 1px 2px 0 rgb(35 57 66 / 21%);
    border: 1px solid transparent;
}

.time-picker-input:focus {
    border: 1px solid #ff5744;
}

.choose-recipients-select {
    max-width: 60%;
    width: 100%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
}

.datetime-picker-modal {
    max-width: 380px;
}

.datetimerange-picker-modal {
    max-width: 600px;
}

.gray-icon {
    margin-right: 5px;
}

.best-price-label {
    position: absolute;
    top: 8px;
    right: -95px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.best-price-label p {
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 12px;
}

.w-49 {
    width: 49%;
}

.font-weight-bold {
    font-weight: bold !important;
}

.inactive-button-group {
    background-color: #fff !important;
    color: black !important;
    border: 1px solid #d8d8d8 !important;
    font-family: "AvenirLTStd-Roman" !important;
}

.inactive-button-group:hover {
    background-color: #fff !important;
}

.w-280px {
    width: 280px;
}

.text-transform-none {
    text-transform: none !important;
}

.shipping-estimation-row {
    border: 1px solid #d8d8d8;
}

.border-top-bottom {
    border-top: 1px solid #d8d8d8 !important;
    border-bottom: 1px solid #d8d8d8 !important;
}

.shipping-estimation-row:hover {
    border: 1px solid #fd5744 !important;
}

.accordion-show {
    overflow: hidden;
    transition: all 0.5s;
}

.accordion-hide {
    transition: all 0.5s;
    overflow: hidden;
}

.estimated-title {
    bottom: -15px;
    font-size: 12px;
}

.w-290px {
    width: 290px;
}

.w-260px {
    width: 260px;
}

.w-190px {
    width: 190px;
}

.position-accordion-arrow {
    bottom: -25px;
    right: 7px;
}

.fz-12 {
    font-size: 12px !important;
}

.fz-11 {
    font-size: 11px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.fz-20 {
    font-size: 20px;
}

.fz-19 {
    font-size: 19px;
}

.font-weight-500 {
    font-weight: 500;
}

.fz-26 {
    font-size: 26px;
}

.fz-15 {
    font-size: 15px;
}

.fz-13 {
    font-size: 13px;
}

.fz-1rem {
    font-size: 1rem;
}

.pl-50 {
    padding-left: 50px;
}

.banners-slider-container {
    width: 100%;
    min-height: 60px;
    background-color: #ff5744;
}

.banner-carousel-item {
    width: 100%;
    min-height: 60px;
    color: white;
    font-size: 1.1rem;
    background-color: #ff5744;
}

.banner-close-button {
    position: absolute;
    top: 2px;
    right: 5px;
    border: none;
    height: 25px;
    width: 25px;
    background-color: transparent;
}

.banner-close-button:focus-visible {
    border: none;
    outline: none;
}

.carousel-item-block-content {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    text-align: center;
    padding: 5px 50px;
    font-weight: 700;
    line-height: 1.1rem;
}

.time-picker-block {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.time-picker-title {
    font-size: 0.75rem;
    font-family: "AvenirLTStd-Roman";
    color: #7d888d;
    line-height: 1.5rem;
    margin-bottom: 7px;
}

.header-title {
    font-size: 15px;
    font-weight: 700;
    font-family: "AvenirLTStd-Roman" !important;
    line-height: 23px;
    color: #212529 !important;
}

.select-label {
    font-size: 13px;
    position: absolute;
    width: 112px;
    top: -25px;
    text-align: center;
}

.select-calculator {
    width: 112px;
    height: 60px;
    font-size: 28px !important;
    font-weight: bold;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(77, 77, 77);
    border-radius: 5px;
}

.select-calculator option {
    font-size: 1rem !important;
}

.select-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.middle-line-calculator {
    width: 455px;
    margin-left: 50px;
    border-top: 1px solid rgb(223, 223, 223);
}

.w-160px {
    width: 160px;
}

.w-140px {
    width: 140px;
}

.w-185px {
    width: 185px;
}

.w-200px {
    width: 200px;
}

.pt-72px {
    padding-top: 72px !important;
}

.calculate-btn {
    height: 60px;
    font-size: 25px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    background-color: rgb(235, 98, 78) !important;
    border: 2px solid rgb(235, 98, 78) !important;
}

.calculator-wrapper {
    width: 1325px;
    background-color: #fff;
}

.ml-50px {
    margin-left: 50px;
}

.w-200px {
    width: 200px;
}

.absolute-bottom-right {
    bottom: 5px;
    right: 20px;
    position: absolute;
}

.question-button {
    font-size: 20px !important;
    width: 160px !important;
}

.min-h-100 {
    min-height: 100px;
}

.min-h-350 {
    min-height: 350px;
}

.min-h-500 {
    min-height: 500px;
}

.fz-10 {
    font-size: 10px !important;
}

.fz-9 {
    font-size: 10px !important;
}

.w-120px {
    width: 120px !important;
}

.h-120px {
    height: 120px !important;
}

.table-header-style {
    padding: 0.5rem 0.5rem;
}

.cost-title {
    color: #fd5744;
    margin-top: 3px;
    font-size: 50px;
}

.reset-calculator {
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.line-height-14 {
    line-height: 14px !important;
}

.list-text-p li {
    font-family: "AvenirLTStd-Book" !important;
    font-weight: 400;
    line-height: 30px;
}

@media screen and (max-width: 1340px) {
    .calculator-wrapper {
        width: 890px;
    }

    .mobile-opt {
        flex-direction: column !important;
        justify-content: center !important;
    }

    .warehouse-btn-mobile {
        flex-direction: column !important;
        justify-content: center !important;
        width: 100%;
        align-items: center !important;
        padding-top: 0px !important;
        margin-bottom: 10px !important;
    }

    .warehouse-btn-mobile div {
        margin-top: 50px;
    }

    .warehouse-btn-mobile div p {
        margin-left: 0 !important;
    }

    .warehouse-btn-mobile div button {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 990px) {
    .warehouse-btn-mobile div {
        margin-top: 20px;
    }
}

.detected-issue-text {
    font-weight: 600;
    margin-top: 20px;
}

.detected-issue-sku {
    display: flex;
}

.red-color-sku-text {
    color: #ff5744;
    font-weight: 600;
    margin-right: 5px;
}

.carton-issue-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information-block-label {
    font-weight: 600;
    margin-right: 5px;
}

.information-issue-block {
    display: flex;
    margin-bottom: 20px;
}

.information-how-to-do-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.how-to-do-text {
    text-align: center;
}

.reconfigure-explanation-block {
    display: flex;
    flex-direction: column;
}

.reconfigure-explanation-text {
    text-align: center;
}

.reconfigure-formula {
    text-align: center;
    color: #ff5744;
}

.information-conclusion {
    font-weight: 600;
    text-align: center;
}

.add-product-from-amazon-error-modal {
    max-height: 80vh;
}

.warning-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.warning-click-here-text {
    font-size: 0.39rem;
    font-weight: 600;
}

.dimension-weight-error {
    border: 1px solid red;
}

.dimension-weight-error:focus {
    border: 1px solid red;
    box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.25);
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-33 {
    width: 33% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.delete-checkbox {
    font-size: 18px !important;
    margin-top: 10px;
}

.delete-checkbox label {
    color: #212529 !important;
}

.packing-list-containers-type {
    text-transform: uppercase;
}

.packing-list-containers-type-block {
    display: flex;
    align-items: center;
    column-gap: 25px;
    cursor: pointer;
}

.containers-type-nav-item {
    color: #4d4d4d;
    padding: 0;
    font-size: 0.85rem;
}

.containers-type-nav-item:hover {
    color: #fd5744;
}

.nav-link:focus,
.nav-link:hover {
    color: #fd5744;
}

.nav-link.active {
    color: #fd5744;
    border-bottom: 1px solid #fd5744;
}

.add-container-form {
    border: 1px solid rgba(109, 109, 109, 0.5);
}

.add-container-additional-inputs-block {
    display: flex;
    width: 100%;
    border-right: 1px solid rgba(109, 109, 109, 0.5) !important;
}

.portal-add-container-additional-inputs-block {
    display: flex;
    width: 100%;
}

.add-container-textarea-wrapper {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(109, 109, 109, 0.5) !important;
    border-left: 1px solid rgba(109, 109, 109, 0.5) !important;
}

.add-container-textarea-wrapper:last-child {
    border-right: none;
}

.add-container-textarea {
    width: 100%;
    resize: none;
}

.add-container-table-body {
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-style: solid !important;
}

.product-table-column {
    vertical-align: middle;
}

.add-container-additional-inputs-row {
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-left: 0px solid rgba(109, 109, 109, 0.5);
    border-right: 0px solid rgba(109, 109, 109, 0.5);
    border-bottom: 1px solid rgba(109, 109, 109, 0.5);
    border-style: solid !important;
}

.add-container-additional-inputs-row:last-child {
    border-bottom: 0px;
}

.add-container-cbm-column {
    vertical-align: middle;
}

.portal-table-header {
    font-size: 0.85rem !important;
}

.portal-error-message {
    color: #e20338;
    font-size: 12px;
}

.portal-invoice-table {
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-style: solid !important;
}

.portal-more-details-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.portal-revert-icon-more {
    transform: rotate(180deg);
}

.container-product-table {
    border: 0px;
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-style: solid !important;
    border-right: 1px solid rgba(109, 109, 109, 0.5);
    border-left: 1px solid rgba(109, 109, 109, 0.5);
}

.container-product-table-head {
    border: 0px;
    border-bottom: 1px solid rgba(109, 109, 109, 0.5);
    border-style: solid !important;
}

.portal-input {
    font-size: 0.85rem;
}

.portal-container-action-block {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 110px;
}

.portal-container-edit-icon {
    cursor: pointer;
}

.container-remove-order-icon {
    cursor: pointer;
}

.detail-table-label {
    font-size: 0.8rem;
    min-width: 110px;
    width: 110px;
    margin-top: 17px;
    font-weight: 600;
}

.download-file-button-detail {
    width: 150px;
    margin-top: 4px;
}

.no-available-containers-text {
    text-align: center;
    font-size: 0.85rem;
    padding: 20px 10px;
}

.portal-filters-container {
    display: flex;
    margin-top: 10px;
}

.portal-search-container {
    background-color: #ffff !important;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    min-width: 240px;
    height: 36px;
}

.portal-search-container input {
    border: none !important;
    outline: none !important;
    width: 90%;
    padding: 0 10px 0 5px;
}

.portal-filter-by-status {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-left: 20px;
}

.portal-filter-by-status-item {
    color: #4d4d4d !important;
    cursor: pointer;
    font-size: 0.85rem;
}

.portal-filter-by-status-item:hover {
    color: #fd5744 !important;
}

.active-status-filter {
    color: #fd5744 !important;
    border-bottom: 1px solid #fd5744;
}

.portal-filter-sort {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.portal-filter-clear-filters {
    display: flex;
    align-items: center;
}

.t-30 {
    top: -30px;
}

.b-30 {
    bottom: -30px;
}

.w-15 {
    width: 15% !important;
}

.w-135px {
    width: 135px;
}

.py-06-btn {
    padding-top: 0.6rem !important;
    padding-bottom: 0.4rem !important;
}

.t40 {
    top: 40px;
}

.affiliate-container {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    padding: 30px 45px;
    margin-bottom: 20px;
    background-color: #fff;
}

.max-w-550px {
    max-width: 550px !important;
}

.max-w-560px {
    max-width: 560px !important;
}

.min-h-75px {
    min-height: 75px;
}

.switch-shipping-plan-alert {
    display: flex;
}

.shipping-popover-alert .popover-arrow {
    top: 50%;
    transform: translate(0, -50%);
}

.additional-information-portal-container-block {
    width: 100%;
    padding: 10px;
}

.container-product-table-invoice {
    border: 0px;
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-style: solid !important;
}

.border-left-2 {
    border-left: 2px solid #d8d8d8;
}

.docusign-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.docusign-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 300px;
}

.t-10px {
    top: 10px;
}

.t-0px {
    top: 0;
}

.t--24px {
    top: -24px;
}

.statistics-column-title {
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.statistics-col {
    border: 1px solid rgba(109, 109, 109, 0.5);
    flex: 0 0 auto;
    height: 180px;
    width: 21%;
    border-radius: 3px;
}

.statistics-column-header {
    color: #4d4d4d;
    text-align: start;
    font-size: 48px;
    width: 100%;
}

.statistic-charts-wrapper {
    max-height: calc(100vh - 320px);
}

.h-40px {
    height: 40px !important;
}

.h-25px {
    height: 25px;
}

.h-80 {
    height: 80% !important;
}

.truncate-after-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.t-5 {
    top: 5px;
}

.r-30 {
    right: 30px;
}

.img-flag {
    width: 35px;
}

.border-bottom-1 {
    border-bottom: 1px solid rgba(109, 109, 109, 0.5);
}

.line-height-1 {
    line-height: 1 !important;
}

.packing-list-containers-sort-by {
    width: 200px !important;
}

.packing-list-modal .modal-xl {
    max-width: 1400px !important;
}

.invoice-details-table {
    border: 1px solid rgba(109, 109, 109, 0.5);
}

.invoice-details-table-border-bottom {
    border-bottom: none !important;
}

.invoice-details-border-right {
    border-right: 1px solid rgba(109, 109, 109, 0.5);
}

.invoice-details-border-right:last-child {
    border-right: none;
}

.fafedex-icon {
    height: 35px;
    width: 40px;
    margin: -10px 0px -10px 0px;
}

.shipping-declaration-modal {
    max-width: 525px;
}

.docusign-uk-note {
    color: #fd5744;
    font-size: 0.8rem;
    margin-top: 10px;
}

.svg-grey-color {
    filter: invert(25%) sepia(32%) saturate(0%) hue-rotate(149deg) brightness(104%) contrast(88%);
}

.dim-gray-color {
    color: #6d6d6d !important;
}

.tabs-container .nav-link {
    border-bottom: 0;
}

.uk-shipping-preparation-description {
    font-size: 0.8rem;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.status-good-icon-color path {
    stroke: green;
}

.status-bad-icon-color path {
    stroke: red;
}

.create-inbound-for-one-market {
    margin-bottom: 0;
    font-size: 15px;
    color: #4d4d4d;
}

.multiple-hs-modal-title-description {
    font-size: 11px;
}

.t-50 {
    top: -50px;
}

.block-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgb(252, 32, 32);
    padding: 0 20px;
}

.maintenance-text {
    font-size: 45px;
    font-weight: 900;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    padding: 10px 30px 20px 30px;
    color: white;
}

.maintenance-text-under {
    font-size: 30px;
    font-weight: 700;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    padding: 0px 32px;
    color: white;
}

.logo-wrapper {
    background-color: #ff5744;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.track-shipments-container {
    height: calc(100vh - 155px);
}

.track-shipments-content {
    margin: 16px 12px;
    display: flex;
    gap: 16px;
    height: 100%;
}

.track-navigation-block {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    height: calc(100vh - 155px);
}

.track-map-block {
    display: flex;
}

.track-navigation-header {
    border: 1px solid rgba(109, 109, 109, 0.5);
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
}

.track-navigation-header-title {
    color: #6d6d6d;
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 400;
}

.track-navigation-containers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 100%;
    margin-top: 16px;
    overflow-y: auto;
    overflow-x: hidden;
}

.track-navigation-container-block {
    display: flex;
    align-items: center;
    border: 1px solid rgba(109, 109, 109, 0.5);
    width: 100%;
    cursor: pointer;
    height: 100%;
    background-color: #fff;
}

.track-navigation-container-block-active {
    display: flex;
    align-items: center;
    border: 1px solid #ff5744;
    outline: 2px solid #ff5744;
    outline-offset: -2px;
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.track-navigation-shipment-info {
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    border-right: 1px solid rgba(109, 109, 109, 0.5);
    width: 40%;
    height: 100%;
}

.track-navigation-container-block-active .track-navigation-shipment-info {
    border-right: 2px solid #ff5744;
}

.track-navigation-info-option {
    display: flex;
    flex-direction: column;
}

.track-navigation-tracking-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    padding: 25px 12px;
    height: 100%;
}

.track-navigation-info-value {
    font-size: 10px;
    color: #6d6d6d;
    margin: 0;
}

.track-navigation-info-title {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
}

.track-navigation-tracking-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.track-navigation-tracking-progress-bar-container {
    width: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    min-height: 60px;
}

.track-navigation-tracking-line-block {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.track-navigation-tracking-footer-block {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.tracking-progress-bar {
    height: 4px;
    background-color: #e2dddd;
}

.tracking-progress-bar .progress-bar {
    background-color: #ff5744;
}

.tracking-start-circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #ff5744;
    border-radius: 10px;
}

.tracking-mid-circle-container {
    width: 13px;
    position: absolute;
    left: 65%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fbfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tracking-mid-circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #e2dddd;
}

.tracking-mid-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tracking-vertical-line {
    background-color: #e2dddd;
    width: 1px;
    height: 15px;
}

.tracking-progres-text {
    font-size: 9px;
    color: #6d6d6d;
}

.tracking-end-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tracking-end-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e2dddd;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
}

.tracking-active-content {
    background-color: #ff5744;
}

.tracking-end-square {
    width: 10px;
    height: 10px;
    background-color: #e2dddd;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
}

.tracking-progress-current-circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #ff5744;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(-10px, -50%);
    z-index: 3;
}

.track-map-block {
    width: 100%;
    border: 1px solid rgba(109, 109, 109, 0.4);
}

.tracking-marker-wrapper {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
    margin-left: -40px;
}

.tracking-marker-inside-block {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #ff5744;
}

.portal-tracking-input {
    background-color: #ffff !important;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    height: 30px;
}

.portal-tracking-input::placeholder {
    font-size: 14px;
}

.portal-multiple-tracking-input {
    background-color: #ffff !important;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    height: 36px;
}

.placeholder-center::placeholder {
    text-align: center;
}

.billing-report-item {
    font-family: "AvenirLTStd-Book" !important;
}

@media screen and (min-device-width: 2600px) and (max-device-width: 3200px) and (min-device-height: 1600px) and (max-device-height: 2200px) {
    .send-to-skudrop-product-description::placeholder {
        font-size: 12px;
    }
}

.multiple-tracking-modal-table {
    max-height: 55vh;
    overflow-y: auto;
}

.text-underline {
    text-decoration: underline !important;
}

.avenir-roman-font {
    font-family: AvenirLTStd-Roman;
}

.tracking-loading-earth-container {
    position: relative;
    width: 200px;
    height: 200px;
}

.tracking-loading-earth {
    width: 100%;
    height: 100%;
}

.tracking-loading-point {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: moveTrackingPoint 20s linear infinite;
    background: transparent;
}

@keyframes moveTrackingPoint {
    0% {
        transform: translate(-50%, -50%);
    }

    10% {
        transform: translate(50%, -20%);
    }

    20% {
        transform: translate(-60%, 0%);
    }

    30% {
        transform: translate(-10%, -70%);
    }

    40% {
        transform: translate(55%, -10%);
    }

    50% {
        transform: translate(-100%, -110%);
    }

    60% {
        transform: translate(35%, -110%);
    }

    70% {
        transform: translate(-75%, 50%);
    }

    80% {
        transform: translate(-65%, -150%);
    }

    90% {
        transform: translate(-125%, -100%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

.lazy-loading-spinner-container {
    min-height: 45px !important;
}

.container-file-upload-checkbox > label {
    color: #212529 !important;
    font-size: 14px !important;
}

.use-credits-switch {
    padding-left: 2.75em !important;
}

.use-credits-switch > input {
    height: 14px !important;
    width: 28px !important;
}

.new-tab-label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 75%;
    z-index: 1;
}

.customer-rates-container {
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
    background-color: #ffffff;
}

.customer-rates-credits-container {
    border: 1px solid rgba(109, 109, 109, 0.5);
    background-color: #ffffff;
}

.customer-rates-payment-container {
    border: 1px solid rgba(109, 109, 109, 0.5);
    background-color: #ffffff;
}

.customer-rates-payments-row {
    display: flex;
    align-items: center;
    height: 68px;
    margin: 0;
}

.customer-rates-payment-input {
    border: 1px solid rgba(109, 109, 109, 0.5);
    width: 160px;
    padding-right: 0;
    border-radius: 0.25rem;
    font-size: 12px;
}

.customer-rates-payment-input-label {
    display: flex;
    align-items: center;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    color: #fd5744;
}

.customer-rates-payment-btn {
    width: max-content;
    height: max-content;
}

.customer-rates-payment-switch-description {
    width: max-content;
    position: absolute;
    top: 24px;
}

.rates-tab.active svg,
.rates-tab.active span,
.rates-tab.active svg path,
.rates-tab.active svg polyline,
.rates-tab.active svg line,
.rates-tab.active svg circle {
    color: #4d4d4d !important;
}

.customer-rates-content-row {
    width: 50%;
    min-width: 600px;
}

.customer-rates-range-row {
    display: flex;
    align-items: center;
    height: 65px;
    margin: 0;
}

.customer-rates-content-labels {
    min-width: 180px;
}

.customer-rates-credits-row {
    display: flex;
    align-items: center;
    height: 68px;
    margin: 0;
}

.rc-slider-tooltip-content {
    min-width: 50px;
    width: max-content;
}

.cutomer-rates-range-preview-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    min-width: 32px;
    padding: 4px 8px !important;
}

.create-new-customer-rate-button {
    position: absolute;
    top: 5;
    right: 0;
}

.w-100px {
    min-width: 100px;
    width: 100px;
}

.customer-rates-option-range-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 0;
    transform: translate(0, -50%);
}

.customer-rates-option-label {
    margin: 0;
    color: #4d4d4d;
    font-size: 15px !important;
    font-family: "AvenirLTStd-Black";
    white-space: nowrap;
}

.customer-rates-option-min-label {
    position: absolute;
    top: 0;
    left: 0%;
    margin: 0;
    transform: translate(-5px, -25%);
    font-family: "AvenirLTStd-Black";
    font-size: 15px !important;
    color: #4d4d4d;
}

.customer-rates-option-value-label {
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -25%);
    color: #fd5744;
    font-size: 17px !important;
    font-family: "AvenirLTStd-Black";
}

.customer-rates-option-max-label {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    transform: translate(5px, -25%);
    font-family: "AvenirLTStd-Black";
    font-size: 15px !important;
    color: #4d4d4d;
}

.table-fba-id-p {
    min-width: 95px;
    margin: 0;
    margin-right: 5px;
    padding: 0;
}

.delivery-type-name {
    margin: 0;
    line-height: 1;
    font-size: 8px;
    color: #6d6d6d;
}

.delivery-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.retes-section-text {
    margin: 0;
    color: #4d4d4d;
    font-size: 14px;
    line-height: 1;
}

.tenant-rates-option-label {
    margin: 0;
    font-size: 15px;
    font-weight: 700;
}

.tenant-rates-option-value {
    margin: 0;
    margin-top: 8px;
    color: #fd5744;
}

.delivery-options-modal > .modal-content {
    min-width: 400px;
    width: fit-content;
}

.delivery-options-modal-header {
    padding: 16px 16px 0 16px !important;
    border: none !important;
}

.delivery-options-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 16px 16px 16px;
}

.delivery-options-modal-small-button {
    padding: 8px 16px !important;
    min-width: 220px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 12px;
}

.delivery-options-modal-large-button {
    padding: 8px 32px !important;
    min-width: 260px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 12px;
}

.delivery-options-modal-button-text {
    font-size: 13px;
    line-height: 1;
    color: #fff;
    margin: 0;
}

.delivery-options-modal-body-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.delivery-options-modal-body-supplier-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.supplier-section-name-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.supplier-section-value-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.track-shipments-search-container {
    display: flex;
    align-items: center;
    background-color: #ffff !important;
    border: 1px solid #ddd;
    padding: 5px;
    width: 100%;
    border-radius: 4px;
}

.track-shipments-search-container input {
    border: none !important;
    outline: none !important;
    width: 80%;
    padding-left: 10px;
    font-size: 14px;
}

.track-shipments-search-container svg {
    width: 20px;
    height: 20px;
}

.tracking-search-clear-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    padding: 0px 8px !important;
    margin-left: auto;
    width: fit-content;
    font-size: 10px !important;
    font-family: "AvenirLTStd-Black" !important;
    border-radius: 4px;
    background-color: #ff5744;
    cursor: pointer;
}

.tracking-search-clear-text,
.send-product-search-clear-text {
    color: #fff;
    margin: 0;
    line-height: 1;
}

.send-product-search-clear-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    padding: 8px !important;
    margin-left: auto;
    font-size: 10px !important;
    font-family: "AvenirLTStd-Black" !important;
    border-radius: 4px;
    background-color: #ff5744;
    cursor: pointer;
}

.send-product-table-body > tr:nth-child(odd) > td {
    background-color: rgba(0, 0, 0, 0.05);
}

.changed-product-table-body-row {
    outline: 1px solid #fdac8a !important;
    outline-offset: -1px !important;
}

.changed-product-table-body-row > td {
    background-color: #fddfd2 !important;
}

.send-product-search-article {
    margin: 0;
    line-height: 1;
    position: absolute;
    left: 38px;
    bottom: -15px;
    font-size: 10px;
}

.send-product-air-switch-block {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: 30px;
}

.send-product-name-truncate {
    overflow: hidden;
    max-width: 160px;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottom-centered-absolute-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: #fff;
    padding: 0 20px;
}

.tax-rule-input-group-text {
    background-color: transparent;
    border-left: none;
    color: #6c757d !important;
}

.tax-rule-modal > .modal-dialog {
    min-width: 75%;
}

.tax-rule-error-message {
    color: #e20338;
    font-size: 12px;
    margin: 0;
    width: calc(100% - 30px);
    text-align: center;
}

.confirm-charge-modal-table {
    max-height: 55vh;
    overflow-y: auto;
}

.min-height-portal-tab {
    min-height: calc(100vh - 260px);
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-top: none;
}

.modal-content-border-radius {
    border-radius: 0.25rem !important;
}

.w-95vw {
    width: 95vw !important;
}

.w-90vw {
    width: 90vw !important;
}

.w-85vw {
    width: 85vw !important;
}

.affiliate-data-modal-body {
    padding-top: 8px;
    height: calc(100% - 60px);
}

.affiliate-data-investment-input {
    font-size: 13px;
    height: 30px;
}

.affiliate-data-statistic-info {
    font-family: "AvenirLTStd-Black";
    font-size: 24px;
    height: min-content;
}

.affiliate-data-statistic-label {
    font-size: 12px;
    margin: 0;
    padding-left: 12px;
}

.portal-image-tooltip > .tooltip-inner {
    padding: 16px;
    min-width: unset !important;
    max-width: unset !important;
    border: 1px solid #e0e0e0;
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}

.details-customer-modal-tbody > tr > td {
    background-color: #f2f2f2;
}

.shipments-details-documents-table-tr {
    border-top: 1px solid #9e9e9e !important;
}

.send-products-integrations-loading-container {
    height: calc(100vh - 120px - 3rem);
    width: calc(100% - 48px);
    padding: 41px 0 0 0;
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    margin: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.send-products-content-loading-container {
    height: calc(100vh - 210px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.send-products-content-loading-message {
    position: absolute;
    left: 50%;
    bottom: -0.5rem;
    margin: 0px !important;
    transform: translate(-50%, 100%);
    width: 100%;
    text-align: center;
}

.send-to-amazon-ff-type-line {
    position: absolute;
    top: 50%;
    border-bottom: 1px solid #d8d8d8;
    width: 90%;
    z-index: 0;
}

.send-to-amazon-ff-type-group {
    z-index: 5;
    padding: 0 24px;
    background-color: #fff;
}

.send-to-amazon-ff-options-line {
    width: 2px;
    height: 30px;
    background-color: #5d5d5d;
    margin: 0 16px;
}

.send-to-amazon-ff-options-switch-label {
    line-height: 1;
    margin: 0;
    padding-top: 1px;
    font-size: 15px;
    color: #6d6d6d;
    font-family: "AvenirLTStd-Book";
    font-weight: bold;
}

.send-to-amazon-ff-options-switch-desc {
    line-height: 1;
    margin: 32px 0 0 0;
    font-size: 8px;
    color: #4d4d4d;
    font-family: "AvenirLTStd-Book";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    text-wrap: nowrap;
    font-weight: bold;
    letter-spacing: 0.2px;
}

.own-ff-switch-img > path,
.own-ff-switch-img > polyline,
.own-ff-switch-img > line {
    color: #6d6d6d !important;
}

.send-to-amazon-ff-measurements-container {
    min-width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.send-to-amazon-ff-measurements-container-label {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 75%);
    width: 100%;
    text-align: center;
    margin: 0;
    padding-top: 4px;
    font-family: "AvenirLTStd-Book";
    font-size: 9px;
}

.send-to-amazon-ff-measurements-value {
    line-height: 1;
    color: #4d4d4d !important;
    margin: 0;
}

.send-to-amazon-ff-measurements-label {
    font-family: "AvenirLTStd-Book";
    color: #4d4d4d !important;
    text-transform: uppercase;
}

.send-to-amazon-ff-measurements-switch {
    margin: 0 2px 2px 0;
}

.send-to-amazon-ff-measurements-switch-label {
    font-family: "AvenirLTStd-Book";
    color: #4d4d4d;
    margin: 0;
    font-size: 9px;
    font-weight: bold;
}

.send-to-amazon-ff-third-step-desc {
    font-family: "AvenirLTStd-Book";
    font-size: 10px;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 85%);
}

.send-to-amazon-ff-file {
    border: 1px solid #d8d8d8;
    width: 200px;
    padding: 5px;
    border-radius: 5px;
}

.send-to-amazon-ff-disabled-plan {
    pointer-events: none;
    opacity: 0.5;
}

.send-to-amazon-ff-details-label {
    color: #4d4d4d;
    font-family: "AvenirLTStd-Book" !important;
    font-size: 14px;
    font-weight: 600;
}

.send-to-amazon-ff-details-input {
    line-height: 1;
    font-size: 14px;
}

.send-to-amazon-ff-reset-details {
    opacity: 1;
    cursor: pointer;
    transition: all 0.2s;
}

.send-to-amazon-ff-reset-details-hidden {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
}

.own-ff-filter-button {
    min-width: 150px;
    text-transform: capitalize !important;
}

.dynamic-component-container {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease-out;
}

.dynamic-component-container.visible {
    grid-template-rows: 1fr;
}

.dynamic-component-content {
    overflow: hidden;
}

.w-350px {
    width: 350px;
}

.rc-slider-tooltip > .tooltip-inner {
    padding: 8px;
    min-width: unset !important;
    max-width: unset !important;
    border: 1px solid #e0e0e0;
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}

.rc-slider-tooltip > .tooltip-arrow::before {
    border-top-color: #6d6d6d;
}

.own-ff-to-amazon-warning-text {
    font-size: 0.4rem;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-align: center;
    color: #fd5744;
}

.container-builder-modal-dialog {
    max-height: unset !important;
    margin: auto !important;
    width: 75vw !important;
}

.container-builder-modal-content {
    height: 90vh !important;
}

.flat-file-modal-text {
    text-align: center;
    text-wrap: balance;
}

.container-builder-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.container-builder-scrollbar::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f1f1f1;
}

.container-builder-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #c1c1c1;
}

.container-builder-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8;
}

.container-builder-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #787878;
}

.own-ff-order-common-details-wrapper {
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-bottom: 1px solid rgba(109, 109, 109, 0.5);
    border-left: 1px solid rgba(109, 109, 109, 0.5);
}

.own-ff-order-common-details-item {
    width: 100%;
    padding: 10px;
    border-right: 1px solid rgba(109, 109, 109, 0.5);
}

.own-ff-order-common-details-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

.own-ff-order-common-details-header {
    font-size: 15px;
}

.send-to-amazon-section-container {
    border: 1px solid rgba(109, 109, 109, 0.5);
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
}

.container-builder-modal-remaining-capacity {
    margin-right: 144px;
}

.container-builder-modal-remaining-capacity-wrapper {
    flex-grow: 1;
}

@media screen and (max-width: 1400px) {
    .container-builder-modal-remaining-capacity {
        margin-left: 32px;
        margin-right: 0;
    }

    .container-builder-modal-remaining-capacity > div {
        gap: 16px !important;
    }

    .container-builder-modal-remaining-capacity-wrapper {
        flex-grow: unset;
    }
}

.own-ff-off-amazon-labels-button-desc {
    margin: 4px 0 0 0;
    width: 100%;
    text-align: center;
    position: absolute;
    font-size: 10px;
}

.z-index-1500 {
    z-index: 1500 !important;
}

.z-index-2000 {
    z-index: 2000 !important;
}

.own-ff-to-amazon-input-error {
    position: absolute;
    font-size: 10px;
    margin: 2px 0 0 12px;
}

.border-radius-0x25rem {
    border-radius: 0.25rem !important;
}

.own-ff-announcement-carousel > .carousel-indicators > button {
    background-color: #d5d5d5 !important;
    margin-left: 7px;
    margin-right: 7px;
}

.own-ff-announcement-carousel > .carousel-indicators > .active {
    background-color: #fd5744 !important;
}

.own-ff-announcement-caption-container {
    border-top: 1px solid #d5d5d5;
    left: 7%;
    right: 7%;
}

.own-ff-announcement-item-container {
    width: 500px;
}

.own-ff-announcement-item-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 275px;
    max-height: 375px;
    height: 45vh;
    margin: 24px;
}

.own-ff-announcement-item-img {
    width: 80%;
}

.own-ff-announcement-caption-desc {
    font-size: 14px;
    text-align: center;
    text-wrap: balance;
}

.own-ff-announcement-caption-title {
    font-size: 18px;
    line-height: 24px;
    white-space: pre-line;
}

.own-ff-prep-order-download-modal {
    max-width: unset;
    width: 650px;
}

.own-ff-prep-downloads-modal-arrow {
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
    transition: background-color 0.15s;
}

.own-ff-prep-downloads-modal-arrow:hover {
    background-color: #d6d6d6;
}

.video-player-modal-dialog {
    max-height: unset !important;
    margin: auto !important;
    width: 75vw;
}

.video-player-modal-content {
    height: unset !important;
    background-color: transparent;
    box-shadow: 0px 0px 12px 1px #bdbdbd;
}

.video-player-modal-close-btn {
    path {
        stroke-width: 40px !important;
    }
}

.video-player-modal-close-btn-container {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: #fd5744;
    border-radius: 50%;
    right: 16px;
    top: 16px;
    z-index: 3;
    box-shadow: 0px 0px 10px 0px #bdbdbd;
    transition: all 0.2s;
}

.video-player-modal-close-btn-container:hover {
    background-color: #ff6858;
    box-shadow: 0px 0px 10px 1px #bdbdbd;
}

.own-ff-agl-modal-slide-container {
    height: 530px;
    padding-left: 24px;
    padding-right: 24px;
}

.own-ff-agl-modal-slide-container {
    p {
        text-wrap: balance !important;
    }
}

.add-public-api-key-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center !important;
    white-space: nowrap !important;
    width: 210px !important;
}

.confirm-api-key-created-btn {
    width: max-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px !important;
    padding: 4px 12px !important;
}

.confirm-api-key-created-text {
    display: flex;
    align-items: center;
    font-size: 16px !important;
    color: #000 !important;
    font-weight: bold !important;
    margin-bottom: 0;
    line-height: 1;
    text-align: center;
}

.confirm-api-key-warning {
    width: 46px;
    height: 39px;
}

.confirm-api-key-created-description {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    line-height: 14px !important;
    margin: 0;
}

.confirm-public-api-key-section {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d6d6d6;
    border-radius: 0.25rem;
}

.floating-placeholder-input {
    position: relative;
    outline: none !important;
    border: none !important;
    width: 100% !important;
    box-sizing: border-box;
    padding: 0 !important;
    z-index: 1;
    background-color: transparent;
}

.floating-placeholder-input:focus {
    outline: none !important;
    border: none !important;
}

.floating-placeholder {
    position: absolute;
    bottom: 0;
    margin: 0;
    font-size: 16px;
    white-space: nowrap;
    color: #7f7f7f;
}

.placeholder-slider {
    --duration: 14s;

    .placeholder-inner {
        display: flex;
        width: fit-content;
        animation-name: floatPlaceholder;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: var(--duration);
        animation-delay: 3s;
    }
}

@keyframes floatPlaceholder {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.help-section-videos-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: fit-content;
    gap: 8px;
}

.help-section-video {
    height: 165px;
}

@media screen and (max-device-width: 1600px) {
    .help-section-videos-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .help-section-video {
        height: 130px;
    }
}

.font-avenir-roman-bold {
    font-family: "AvenirLTStd-Roman";
}

.shipments-consolidation-best-plan-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1;
    background-color: #fff;
    margin: 8px;
}

.send-to-skudrop-bulk-invoice-modal-input::placeholder {
    text-align: center;
    padding-left: 20px;
}

.floating-input-placeholder-container {
    border: 1px solid #dee2e6 !important;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.floating-input-placeholder-input {
    position: relative;
    width: 100% !important;
    padding: 6px 12px;
    z-index: 1;
    background-color: transparent;
    border: none !important;
}

.floating-input-placeholder-input:focus {
    outline: none;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.floating-input-placeholder-content-box {
    width: calc(100% - 26px);
    background-color: transparent;
    margin: 0 12px;
    position: absolute;
    bottom: 50%;
    transform: translate(0, 50%);
    display: flex;
    align-items: center;
}

.floating-input-placeholder-content {
    font-size: 15px;
    white-space: nowrap;
    color: #53575b;
    overflow-x: hidden;
}

.general-modal-header-title {
    font-size: 22px;
    line-height: 1;
}

.customer-tiers-range-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    margin: 0;
}

.customer-tiers-content-row {
    width: 50%;
    min-width: 875px;
}

.customer-tiers-input {
    width: 110px;
    line-height: 1;
}

.customer-tiers-input-label {
    margin: 0;
    position: absolute;
    transform: translate(-50%, -100%);
    top: 0;
    left: 50%;
    text-wrap: nowrap;
    font-size: 14px;
}

.third-customer-tier-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: -5px;
    width: -webkit-fill-available;
}

.third-customer-tier-label {
    font-family: "AvenirLTStd-Black";
    font-size: 15px !important;
    color: #4d4d4d;
}

.third-customer-tier-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.customer-tiers-cbm-range-input {
    font-size: 17px;
    color: #fd5744;
    border-radius: 4px;
    border: 1px solid #dedede;
    padding-inline: 8px;
    width: 100px;
}

.customer-tiers-cbm-range-input:focus-visible {
    border: 1px solid #dedede;
    outline: unset;
}

.tab-scroller-container {
    display: flex;
    align-items: center;
    position: relative;
}

.tab-scroller {
    overflow: hidden;
    width: 100%;
}

.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.tab-scroller-arrow-container {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: #fff;
}

.tab-scroller-arrow-container.left-arrow {
    left: 0;
    border-top-right-radius: 4px;
}

.tab-scroller-arrow-container.right-arrow {
    right: 0;
    border-top-left-radius: 4px;
}

.tab-scroller-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    padding-block: 4px;
    padding-inline: 6px;
    background-color: #fff;
}

.tab-scroller-arrow.left-arrow {
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-left: 1px solid rgba(109, 109, 109, 0.5);
    border-right: 1px solid rgba(109, 109, 109, 0.5);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -10px 0px 0px);
}

.tab-scroller-arrow.right-arrow {
    border-top: 1px solid rgba(109, 109, 109, 0.5);
    border-left: 1px solid rgba(109, 109, 109, 0.5);
    border-right: 1px solid rgba(109, 109, 109, 0.5);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px 0px 0px -10px);
}

.tab-scroller-nav-container > .nav-item > .nav-link {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

.tab-scroller-nav-container {
    gap: 5px;
}

.tab-scroller-dropdown {
    position: absolute;
    max-height: 30vh;
    overflow-y: auto;
    min-width: 140px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-block: 6px;
}

.tab-scroller-dropdown.right {
    right: 0;
}

.tab-scroller-dropdown.left {
    left: 0;
}

.tab-scroller-dropdown-item {
    padding: 8px 10px;
    line-height: 1;
}

.tab-scroller-dropdown-item:hover {
    background-color: #ececec;
    cursor: pointer;
}

.delivery-details-divider {
    position: relative;
    width: 90%;
    border-top: 1px solid #b4b4b4;
    margin-block: 32px;
}

.delivery-details-divider-text {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-wrap: nowrap;
    background-color: white;
    padding-inline: 24px;
    font-size: 16px;
    margin-bottom: 0;
}

.reset-selected-supplier-details {
    opacity: 0;
    position: absolute;
    right: -20%;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    visibility: hidden;
    transition: all 0.2s;
}

.reset-selected-supplier-details.visible {
    opacity: 1;
    visibility: unset;
    transition: all 0.2s;
}

.min-w-550 {
    min-width: 550px;
}

.min-w-850 {
    min-width: 850px;
}

.product-name-tooltip {
    transition: none !important;
}

.product-name-tooltip > .tooltip-inner {
    border: 1px solid #6d6d6d80;
}

.product-name-tooltip > .tooltip-arrow::before {
    border-left-color: #bebebe !important;
    border-right-color: #bebebe !important;
}

.billing-section-rates-line {
    width: 98%;
    border-bottom: 1px solid #6d6d6d80;
}

.manual-quoting-page-container {
    margin: 24px;
    border: 1px solid #6d6d6d80;
    border-radius: 4px;
    background-color: #fff;
    max-height: calc(100% - 48px);
    position: relative;
    overflow-y: auto;
}

.request-quote-step-container {
    border: 1px solid #6d6d6d80;
    border-radius: 4px;
    padding: 16px;
}

.request-quote-step-name {
    font-family: "AvenirLTStd-Black";
    font-size: 15px;
    color: #4d4d4d;
}

.request-quote-options-line {
    width: 2px;
    height: 30px;
    background-color: #5d5d5d;
}

.select-fc-dropdown > .dropdown-menu {
    min-width: unset !important;
}

.request-quote-bordered-first-row {
    border: 1px solid #ffaea5 !important;
    border-bottom: 1px solid #ffaea5 !important;
}

.request-quote-bordered-second-row {
    border: 1px solid #ffaea5 !important;
}

.max-w-450 {
    max-width: 450px !important;
}

.accept-quote-horizontal-line {
    border-bottom: 1px solid #6d6d6d80;
    width: 80%;
}

.request-quote-selected-fcs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #fd5744;
    border-radius: 4px;
    padding-inline: 16px;
    height: 30px;
    font-family: "AvenirLTStd-Black";
    font-size: 13px;
}

.request-quote-modal-dialog {
    max-height: unset !important;
    margin: auto !important;
    width: 90vw !important;
    max-width: 1600px !important;
}

.request-quote-modal-content {
    height: 95vh !important;
    max-height: 1200px !important;
}

.dropdown-toggle-arrow::after {
    vertical-align: 0.2em !important;
}

.request-quote-loading-dock-switch-label {
    font-size: 10px;
    color: #4d4d4d;
    font-family: "AvenirLTStd-Book";
    text-wrap: nowrap;
    font-weight: bold;
}

.quote-measurements-container {
    display: flex;
    flex-direction: column;
}

.quote-measurements-container-label {
    width: 100%;
    margin: 0;
    font-family: "AvenirLTStd-Book";
    font-size: 10px;
}

.max-w-650px {
    max-width: 650px !important;
}

.max-w-700px {
    max-width: 700px !important;
}

.live-quote-details-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #fd5744;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    font-family: "AvenirLTStd-Black";
    font-size: 13px;
    cursor: pointer;
}

.live-quote-details-button:hover {
    background-color: var(--mouse-over-color);
}

.live-quote-details-modal-stats-container {
    padding: 10px;
    border-right: 1px solid rgba(109, 109, 109, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    flex-grow: 1;
    max-width: 20%;
    gap: 8px;
}

.live-quote-details-modal-stats-container-header {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    margin: 0;
}

.custom-dropdown-item.active,
.custom-dropdown-item:active,
.custom-dropdown-item:focus {
    color: var(--bs-dropdown-link-color) !important;
    text-decoration: none !important;
    background-color: unset !important;
}

.custom-dropdown-item:hover {
    background-color: var(--bs-dropdown-link-hover-bg) !important;
}

.notification-section-category-label {
    font-size: 18px;
    color: #4d4d4d;
    line-height: 1;
    margin: 0;
}

.notification-section-subcategory-label {
    font-size: 16px;
    color: #4d4d4d;
    line-height: 1;
    margin: 0;
}

.notification-section-subcategory-description {
    color: #6d6d6d;
    line-height: 1;
    margin: 0;
}

.notification-subsection-divider {
    height: 1px;
    border-top: 1px solid #6d6d6d80;
}

.reporting-page-wrapper {
    margin: 16px;
    box-sizing: border-box;
}

.reporting-page-content {
    border: 1px solid #6d6d6d80;
    background-color: #fff;
}

.reporting-page-section {
    padding: 16px;
    box-sizing: border-box;
}

.reporting-container {
    width: 500px;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
}

.reporting-flex-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reporting-cell-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 155px;
    position: relative;
}

.reporting-cell-title {
    font-family: "AvenirLTStd-Black";
    color: #4d4d4d;
    font-size: 16px;
    margin: 0;
}

.stored-products-dropdown-menu {
    max-height: 200px;
    width: unset !important;
    overflow-y: auto !important;
    min-width: 100% !important;
}

.reporting-title {
    font-family: "AvenirLTStd-Black";
    color: #4d4d4d;
    font-size: 18px;
    margin: 0;
}

.reporting-cell-description {
    font-family: "AvenirLTStd-Roman";
    color: #6d6d6d;
    font-size: 11px;
    margin: 0;
}

.reporting-cell-value {
    font-family: "AvenirLTStd-Black";
    color: #4d4d4d;
    font-size: 14px;
    margin: 0;
}

.reporting-gap-line {
    border-bottom: 1px solid #6d6d6d80;
}

.stored-products-dropdown-menu {
    max-height: 200px;
    width: unset !important;
    overflow-y: auto !important;
    min-width: 100% !important;
}

.amazon-plan-best-option-mark-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0px, calc(-100% - -5px));
    display: flex;
    justify-content: center;
    width: 100%;
}

.amazon-plan-best-option-title {
    color: #fd5744;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    padding-inline: 16px;
    text-transform: uppercase;
    margin: 0;
}

.amazon-plan-best-option-text-mark {
    text-align: center;
    font-size: 11px;
    margin: 0px;
    position: absolute;
    top: 4px;
    width: inherit;
}

.amazon-plan-slit-type {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 0;
}

.amazon-plan-sections-divider-line {
    border-top: 1.5px solid #6d6d6d80;
    height: 1px;
    position: relative;
}

.amazon-plan-split-placement-fee-desc-wrapper {
    background-color: #fd5744;
    padding: 6px 10px;
    border-radius: 4px;
}

.amazon-plan-split-placement-fee-desc {
    line-height: 1;
    font-size: 12px;
    color: white;
    font-weight: bold;
    font-family: "AvenirLTStd-Black";
    text-transform: uppercase;
    margin: 0;
}

.amazon-placement-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 33%;
    max-width: 350px;
    box-sizing: border-box;
    border: 2px solid transparent;
    padding: 16px;
    position: relative;
}

.amazon-blank-placement-gray {
    color: #8d8d8d;
}

.amazon-blank-placement-background-gray {
    background-color: #8d8d8d;
}

.amazon-blank-placement-button-gray {
    background-color: #8d8d8d !important;
    cursor: unset !important;
}

.amazon-blank-placement-button-gray:hover {
    background-color: #8d8d8d !important;
}

.select-inbound-region-dropdown-menu {
    min-width: 100% !important;
    max-width: unset !important;
    width: unset !important;
    padding-block: 4px;
}

.filter-by-month-modal {
    width: fit-content !important;
}

.reporting-date-range .rdrMonthAndYearWrapper {
    margin-inline: 4px;
}

.reporting-date-range .rdrMonthsHorizontal {
    margin-inline: 4px;
}

.reporting-date-range .rdrDateDisplayWrapper {
    padding-inline: 4px;
}

.empty-state-active-mq {
    text-align: center;
    margin: 0;
    font-size: 20px !important;
}

/* Outbound orders preselect */

.outbound-preselect-container {
    border: 1px solid #6d6d6d80;
    border-radius: 4px;
    margin: 24px;
    background-color: #fff;
    padding: 16px;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.outbound-preselect-section {
    min-height: -webkit-fill-available;
}

.outbound-preselect-options-row {
    flex-grow: 1;
    display: flex;
    gap: 16px;
}

.outbound-preselect-option-column {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 1 0;
    max-width: 33%;
    /* max-width: 400px; // for inbound preselect page */
}

.outbound-preselect-option-title {
    display: flex;
    justify-content: center;
}

.outbound-preselect-option-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.outbound-preselect-option-choices {
    border: 1.5px solid #6d6d6d80;
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.outbound-preselect-option-choices-open {
    display: flex;
    flex-direction: column;
}

.outbound-preselect-option-choice-wrapper {
    display: flex;
    border: 1.5px solid #cccccc;
}

.outbound-preselect-option-choice-wrapper.active {
    border: 1.5px solid #fd5744;
}

.outbound-preselect-option-choice-wrapper.active h5 {
    color: #fd5744;
}

.outbound-preselect-option-choice-wrapper.active .outbound-preselect-option-choice-flag-wrapper {
    border-left: 1.5px solid #fd5744;
}

.outbound-preselect-option-choice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    border: 1.5px solid #cccccc;
    cursor: pointer;
}

.outbound-preselect-option-choice.active {
    border: 1.5px solid #fd5744;
}

.outbound-preselect-option-choice.active h5 {
    color: #fd5744;
}

.outbound-preselect-option-choice-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 16px;
}

.outbound-preselect-option-choice-title {
    font-size: 18px;
    margin: 0;
}

.outbound-preselect-option-choice-desc {
    font-size: 11px;
    margin: 0;
}

.outbound-preselect-option-choice-flag-wrapper {
    display: flex;
    align-items: center;
    border-left: 1.5px solid #cccccc;
    padding: 16px;
}

.outbound-preselect-options-preview-row {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    border: 1.5px solid #6d6d6d80;
    margin-top: 16px;
}

.outbound-preselect-options-preview-options {
    display: flex;
    gap: 16px;
}

.outbound-preselect-option-preview-column {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    flex: 1 1 0;
}

.outbound-preselect-options-preview-choice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 16px;
    border: 1.5px solid #6d6d6d80;
    min-height: 81px;
    align-items: center;
}

.outbound-preselect-options-preview-choice.active {
    border: 1.5px solid #fd5744;
}

.outbound-preselect-options-preview-choice-title {
    font-size: 18px;
    color: #fd5744;
    margin: 0;
}

.outbound-preselect-options-connector {
    display: flex;
    align-items: center;
    width: calc(calc(-10% + 50vw) / 2.9);
    position: absolute;
    right: -8px;
    top: calc(50% - 2px);
    transform: translate(100%, -50%);
}

.outbound-preselect-options-arrow-right {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid #000;
}

.outbound-preselect-options-dots {
    flex-grow: 1;
    border-top: 2px dotted #000;
}

.outbound-preselect-options-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
}

.outbound-preselect-options {
    padding: 2px 8px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #cccccc;
    background-color: #fff;
    /* max-width: 290px;   */
    overflow: auto;
    /* text-wrap: wrap; */
}

.outbound-preselect-options.active {
    background-color: #fd5744;
    color: #fff;
}

.outbound-preselect-options-warehouse-list {
    border: 1.5px solid#fd5744;
}

.outbound-preselect-options-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.warehouse-dropdown-menu {
    max-height: 200px;
    width: unset !important;
    overflow-y: auto !important;
    min-width: 100% !important;
}

.amazon-plan-animated-loading-icon-column {
    display: flex;
    position: relative;
    width: 48px;
}

.amazon-plan-animated-loading-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    transition: top 0.15s linear;
}

.amazon-plan-animated-loading-steps-column {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    max-height: 145px;
}

.amazon-plan-animated-loading-step {
    margin: 0;
    color: #d2d2d2;
    line-height: 2;
    font-size: 24px;
    transition: color 0.15s linear;
}

.amazon-plan-animated-loading-step.active {
    color: unset;
}

.amazon-plan-animated-loading-img {
    animation: progress-spinner-rotate 2s linear infinite;
}

.disable-img-drag {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.delivery-windows-dropdown > .dropdown-menu {
    max-height: 175px !important;
}

.w-440px {
    width: 440px !important;
}

.warehouse-search-container {
    background-color: #ffff !important;
    border: 1px solid #ddd;
    padding: 5px;
    width: 90%;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.warehouse-search-container input {
    border: none !important;
    outline: none !important;
    width: 80%;
    padding-left: 8px;
}

.warehouse-search-container svg {
    width: 20px;
    height: 20px;
}

.tracking-shipments-page-container {
    margin: 24px;
    border: 1px solid #6d6d6d80;
    border-radius: 4px;
    background-color: #fff;
    max-height: calc(100% - 48px);
}

.tracking-shipments__label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
}

.tracking-shipments-table-lable-bar {
    position: sticky;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    top: 0px;
    background-color: white;
    z-index: 5;
}

.tracking-shipments-table-lable-bar-line {
    border-bottom: 1px solid #fd5744;
    height: 1px;
    position: absolute;
    width: calc(100% - 30px);
    top: 19px;
    z-index: 5;
}

.tracking-shipments-table-lable-bar::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 1px;
    height: 8px;
    background-color: #fd5744;
}

.tracking-shipments-table-lable-bar::after {
    content: "";
    position: absolute;
    top: 15px;
    right: 30px;
    width: 1px;
    height: 8px;
    background-color: #fd5744;
}

.tracking-shipments-table-lable-bar p {
    position: absolute;
    top: 11px;
    background-color: #ffffff;
    color: #fd5744;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 6;
}

.tracking-shipments-table-lable-bar svg {
    margin-top: -1.5px;
}

.track-shipments-customize-block {
    position: absolute;
    right: 0;
    top: 42px;
    background-color: white;
    box-shadow: 0px 0px 6px 0px #bdbdbd;
    z-index: 100;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
}

.track-shipments-customize-item {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    font-size: 14px;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
    font-weight: 600;
    color: #4d4d4d;
    user-select: none;
}

.track-shipments-customize-item:last-child {
    border-bottom: none;
}

.track-shipments-customize-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 14px;
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    white-space: nowrap;
}

.track-shipments-customize-item input {
    width: 15px;
    height: 15px;
    margin-bottom: 2px;
    cursor: pointer;
}

.track-shipments-customize-item input:checked {
    accent-color: #fd5744;
}

.tracking-shipments-table-lable-bar-collapsed {
    position: relative;
    width: 36px;
    height: calc(100% - 10px);
    background-color: #f2f2f2;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.tracking-shipments-table-lable-bar-collapsed svg {
    margin-top: -3.5px;
}

.tracking-shipments-table-lable-bar-collapsed::after {
    content: "";
    position: absolute;
    top: 18px;
    left: 18px;
    width: 1px;
    height: calc(100% - 36px);
    background-color: #fd5744;
}

.tracking-shipments-table-lable-bar-collapsed::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 16px;
    width: 5px;
    height: 155px;
    background-color: #f2f2f2;
    z-index: 2;
}

.tracking-shipments-table-lable-bar-collapsed p {
    position: absolute;
    top: 175px;
    background-color: #f2f2f2;
    color: #fd5744;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 0 12px;
    transform: rotate(-90deg);
    width: 100%;
    white-space: nowrap;
    left: 2px !important;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 8px;
}

.tracking-table-collapsing-animation {
    animation: table-collapsing 0.5s ease-in-out;
    transform-origin: left;
    position: relative;
}

@keyframes table-collapsing {
    0% {
        transform: scaleX(1);
        opacity: 1;
    }

    100% {
        transform: scaleX(0);
        opacity: 0;
    }
}

.tracking-table-header-collapsing-animation {
    animation: table-header-collapsing 0.5s ease-in-out;
    transform-origin: left;
    position: relative;
}

@keyframes table-header-collapsing {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.whitespace-nowrap {
    white-space: nowrap;
}

.tracking-shipments-dropdown-menu {
    width: 205px !important;
}

.tracking-shipments-status-badge {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d5d5d5;
    height: 24px;
    padding: 0 6px;
    border-radius: 4px;
}

.no-button-style {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.tracking-table-tooltip {
    background-color: rgb(60, 59, 59);
    color: #ffffff;
    border-radius: 4px;
    padding: 0 8px;
    height: 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    z-index: 101;
}

.tracking-table td {
    white-space: nowrap;
}

.tracking-table-sku-list-more {
    color: #fd5744;
    font-weight: 600;
}

.tracking-sticky-header {
    position: sticky;
    top: 40px;
    background-color: #fff;
}

.w-1100px {
    width: 1100px !important;
    min-width: 1100px !important;
}

.w-510px {
    width: 510px !important;
    min-width: 510px !important;
}

.w-380px {
    width: 380px !important;
    min-width: 380px !important;
}

.bordered-step-container {
    border: 1px solid #6d6d6d80;
    border-radius: 4px;
    padding: 16px;
}

.freight-forwarders-table-wrapper {
    max-height: 500px;
    overflow-y: auto;
    position: static;
}

.freight-forwarders-input-group {
    visibility: visible;
    position: static;
}

.freight-forwarders-input-group .dropdown-menu {
    max-width: 200px;
    right: 65px;
}

.vendor-skus-mapping-checkbox {
    height: 19px;
    width: 19px;
}

.vendor-skus-mapping-checkbox > input {
    height: 19px;
    width: 19px;
    margin-top: 0 !important;
}

.vendor-skus-mapping-type-switch-label {
    line-height: 1;
    margin: 0;
    padding-top: 1px;
    font-size: 12px;
    color: #6d6d6d;
    font-family: "AvenirLTStd-Book";
    font-weight: bold;
}

.max-h-250px {
    max-height: 250px !important;
}

.secondary-bootstrap-button {
    background-color: #6d6d6d !important;
}

.secondary-bootstrap-button:hover {
    background-color: #6d6d6d !important;
    opacity: 0.9 !important;
}

.max-w-620px {
    max-width: 620px !important;
}

.outbound-order-page-back-button {
    fill: #4d4d4d;
    transition: fill 0.2s;
}

.outbound-order-page-back-button:hover {
    fill: #fd5744;
}

.request-quote-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    border: 1px solid #6d6d6d80;
    border-radius: 4px;
    background-color: #fff;
    padding: 16px;
    margin: 24px;
}

.vendor-sku-hint-label {
    margin: 0;
    color: #4d4d4d;
}

.vendor-sku-hint-value {
    font-weight: bold;
    margin: 0;
    color: #4d4d4d;
}

.adaptive-tooltip {
    transition: none !important;
}

.adaptive-tooltip > .tooltip-inner {
    border: 1px solid #6d6d6d80;
    max-width: unset;
}

.adaptive-tooltip > .tooltip-arrow::before {
    border-left-color: #bebebe !important;
    border-right-color: #bebebe !important;
}
