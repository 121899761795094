.block {
    height: 100vh !important;
}
.max-h-100vh {
    max-height: 100vh;
}
.info-block {
    background-color: var(--main-background-color);
}
.auth-form-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.logo-absolute {
    position: absolute;
    top: 20px;
}
.no-link-amazon {
    color: #111;
}
.no-link-amazon:hover {
    color: #111;
}
.auth-form {
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    width: 55%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-width: 400px;
    min-height: 300px;
    background-color: #fff;
}
.large-form {
    min-height: 100%;
    height: max-content;
}
.third-step {
    width: 70%;
    max-width: 100%;
    min-height: 200px;
}
.skudrop-logo {
    width: 50%;
}
.font-bold {
    font-family: "AvenirLTStd-Black";
    font-size: 15px;
}
.logo-left {
    max-width: 150px;
}
.text-size-small {
    font-size: 13px !important;
}
.spiner-container {
    width: 100vw !important;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none !important;
}
.complete {
    max-width: 620px;
    border-radius: 0.5rem;
    padding: 25px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}
.error-message {
    color: #e20338;
    font-size: 12px;
    position: absolute;
    margin-top: 0.1rem;
}
.input:focus {
    border-color: #e20338 !important;
    box-shadow: 0 0 0 0.25rem rgba(226, 3, 56, 0.25);
}
.input {
    border-color: #e20338 !important;
}
.have-account {
    color: var(--action-button-color);
    margin-left: 5px;
    cursor: pointer;
}
.login-background {
    background-color: #e93e2a;
}
.DemoWrapper {
    margin: 0 auto;
    max-width: 250px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    /* padding: 10px 14px; */
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    /* box-shadow: rgba(55, 52, 65, 0.5) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
    outline: 0;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    border-radius: 0.25rem;
    background: white;
}
.CardElement {
    max-width: 100% !important;
}
.ElementsApp,
.ElementsApp .InputElement {
    font-family: none !important;
}

.StripeElement--focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.card-cvc {
    width: 35%;
}

.card-mmyy {
    width: 55%;
}

/* .sub-domain:active .sub-domain-hint {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
} */

.sub-domain {
    border-radius: 4px 0px 0px 4px !important;
    transition:
        border-color 0.15s,
        box-shadow 0.15s;
    clip-path: inset(-5px 0.9px -5px -5px);
}
.input-group-text {
    border-radius: 0 4px 4px 0 !important;
    transition:
        border-color 0.15s,
        box-shadow 0.15s;
    clip-path: inset(-5px -5px -5px 0.1px);
}
.input-group-text-dashboard {
    border-radius: 4px 0 0 4px !important;
    transition:
        border-color 0.15s,
        box-shadow 0.15s;
    clip-path: inset(-5px -5px -5px 0.1px);
}
.sub-domain:focus + .input-group-text {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    border-radius: 0 4px 4px 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    clip-path: inset(-5px -5px -5px 1.1px);
}

.sub-domain:focus {
    clip-path: inset(-5px 0px -5px -5px);
}
.input-hr {
    display: block;
    position: absolute;
    width: 67%;
    left: 3%;
    top: 65%;
}

.sub-domain:focus ~ hr {
    display: none !important;
}
.login-with-amazon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    flex-direction: row;
    font-family: "AvenirLTStd-Roman" !important ;
    text-transform: capitalize !important;
    color: #111 !important;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset !important;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b) !important;
}
.min-width-520 {
    min-width: 520px;
}

.complete-h2-text {
    font-weight: bold;
}
.regiter-subdomain {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 200px !important;
    text-align: center !important;
}
.complete-step-bg {
    position: absolute;
    z-index: 0;
}
.z-index-99 {
    z-index: 99;
}

.signup-complete-background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media (max-width: 1200px) {
    .info-image-container {
        display: none;
    }
}
@media (max-width: 990px) {
    .login-background img {
        width: 90%;
        object-fit: contain;
    }
    .check-email-img {
        width: 50% !important;
    }
    .check-email-title {
        font-size: 19px !important;
    }
    .check-email-container {
        margin-bottom: 10px !important;
    }
    .login-with-amazon-btn {
        width: 180px !important;
    }
    .min-width-520 {
        min-width: 0;
        width: 90%;
    }
    .text-size-small-small {
        font-size: 15px;
    }

    .register-coplete-btn {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 5px !important;
    }

    .register-complete-image {
        width: 50% !important;
        max-width: 220px !important;
    }
}
