.study-hero {
    width: 100vw;
    background-color: #fafbfe;
    display: flex;
    align-items: center;
    justify-content: center;
}
.study-hero-block {
    max-width: 1410px;
    width: 70%;
    min-height: 450px;
    background-color: #faf4f7;
    padding: 90px;
    padding-right: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.study-hero-label {
    font-size: 48px;
    color: #000;
    line-height: 60px;
}
.primary-color {
    color: #f96251 !important;
}
.study-hero-description {
    font-size: 16px;
    line-height: 25px;
    color: #000;
}
.study-hero-subtitle {
    font-size: 25px;
    line-height: 36px;
    color: #000;
}
.data-table {
    width: 100%;
    background-color: #fafbfe;
    display: flex;
    align-items: center;
    justify-content: center;
}
.data-table-block {
    max-width: 1250px;
    width: 100%;
    padding-right: 40px;
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow-x: auto;
}
.active-color {
    color: #f96251;
}
.th-image {
    width: 100%;
    max-width: 309px;
}
.w-33 {
    width: 33%;
}
.th-title {
    font-size: 25px;
    line-height: 34px;
}
.th-title-sm {
    font-size: 16px;
    line-height: 24px;
}
.th-text {
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    font-weight: lighter;
}
.max-w-300 {
    max-width: 300px;
}
.green {
    color: rgba(63, 197, 82, 1) !important;
    font-weight: 700;
    font-family: "AvenirLTStd-Black";
}
.red {
    color: rgba(245, 39, 15, 1) !important;
    font-weight: 700;
    font-family: "AvenirLTStd-Black";
}
.green-small {
    color: rgba(63, 197, 82, 1);
}

.red-small {
    color: rgba(245, 39, 15, 1);
}
.td-background {
    background-color: rgba(246, 246, 246, 1);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    min-width: 300px;
}
.bold {
    font-weight: 500;
}
.td-border {
    border: 1px solid rgba(246, 246, 246, 1);
    padding: 0;
}
.totals {
    border: 1px solid rgba(0, 0, 0, 0.29);
    min-height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.total-title {
    font-size: 20px;
    line-height: 34px;
    color: #000;
}
.xl-numbers {
    font-size: 48px;
    line-height: 34px;
}
.gray {
    color: rgba(77, 77, 77, 1);
}
.pt-65 {
    padding-top: 65px;
}
.pt-25 {
    padding-top: 25px;
}
.first-column-color {
    color: rgba(38, 38, 38, 1) !important;
}
.secondary-text {
    color: rgba(38, 38, 38, 1);
    font-size: 16px;
    line-height: 20px;
    padding-left: 90px;
    font-weight: 900;
}
.results-block {
    border: 1px solid rgba(191, 191, 191, 1);
    max-width: 1565px;
    background-color: #fff;
    padding: 110px 60px 110px 80px;
    width: 80%;
}
.results-title {
    color: rgba(0, 0, 0, 1);
    font-size: 48px;
    line-height: 34px;
}
.fz-45 {
    font-size: 48px;
}
.results-number {
    font-size: 48px;
    color: rgba(63, 197, 82, 1);
    margin-bottom: 0 !important;
}
.results-number-description {
    font-size: 16px;
    line-height: 34px;
    color: rgba(0, 0, 0, 1);
}
.conclusion-text {
    font-size: 16px;
    line-height: 25px;
    color: rgba(77, 77, 77, 1);
}
.numbers-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
}
.tariffs-position {
    height: 174px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.first-column {
    min-width: 252px;
    z-index: 999;
    position: sticky;
    left: 0;
    background-color: #fafbfe;
    padding-left: 40px;
}
.first-column p {
    max-width: 300px;
}
@media (max-width: 1440px) {
    .study-hero-block {
        padding-right: 150px;
    }
}

@media (max-width: 1280px) {
    .totals {
        min-height: 185px;
    }
    .total-title {
        font-size: 16px;
        line-height: 24px;
    }
    .xl-numbers {
        font-size: 30px;
    }
}

@media (max-width: 990px) {
    .results-block {
        padding: 27px;
    }
    .results-title {
        font-size: 30px !important;
    }
    .study-hero-block {
        padding: 20px;
    }
    .study-hero-label {
        font-size: 30px;
        line-height: 44px;
    }
    .study-hero-block {
        width: 90%;
    }
    .study-hero-subtitle {
        font-size: 18px;
        line-height: 30px;
    }
}

@media (max-width: 800px) {
    .numbers-container {
        flex-direction: column;
    }
}

@media (max-width: 580px) {
    .first-column {
        min-width: 150px;
        padding-left: 10px;
    }
    .td-background {
        min-width: 210px;
    }
    .th-title {
        font-size: 13px;
    }
    .th-text {
        font-size: 12px;
    }
    .th-title-sm {
        font-size: 14px;
    }
    .secondary-text {
        font-size: 8px;
        padding-left: 50px;
    }
    .totals {
        min-height: 179px;
    }
    .totals p {
        text-align: center;
    }
    .total-title {
        font-size: 10px;
        line-height: 14px;
    }
    .xl-numbers {
        font-size: 24px;
    }
}
