.landing {
    width: 100vw;
}

.logo-width {
    width: 15vw;
}

.nav-bg {
    background-color: #fafbfe !important;
}

.menu-icon {
    display: none;
    width: 30px;
    height: 30px;
}

.max-width-1920 {
    max-width: 1920px !important;
    padding-right: 5% !important;
    padding-left: 5% !important;
}

.hero-section {
    width: 100%;
    background: linear-gradient(280.36deg, rgba(255, 87, 68, 0.04) 6.93%, rgba(255, 87, 68, 0.0368) 46.46%);
}

.hero-block {
    max-width: 1920px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
}

.hero-title {
    font-family: "AvenirLTStd-Black";
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    font-weight: 800;
    color: #4d4d4d;
    width: 95%;
}

.title-position {
    margin-top: 20px;
}

.hero-desc {
    font-size: 22px !important;
    line-height: 32px !important;
    text-align: center;
    max-width: 70%;
    margin-top: 10px;
}

.hero-img {
    margin-top: 10px;
    max-width: 100%;
}

.hero-btn {
    margin-top: 40px;
}

.feature-section {
    max-width: 30%;
    min-width: 200px;
    width: 25%;
}

.feature-bg {
    background-color: #fafbfe;
}

.feature-image-container {
    background-color: #faf4f6;
    height: 109px;
    width: 109px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-headline {
    font-family: "AvenirLTStd-Black";
    font-size: 25px;
    line-height: 36px;
}

.feature-text {
    font-family: "AvenirLTStd-Book" !important;
    font-size: 16px;
    line-height: 28px;
}

.section-width {
    width: max-content;
}

.section-height {
    height: 490px;
    width: 100%;
}

.result-section {
    max-width: 30%;
    min-width: 200px;
    width: 50%;
}

.h-35 {
    height: 35%;
}

.pricing-cards {
    flex-wrap: wrap;
    width: 100%;
}

.pricing-card {
    flex-basis: 25%;
    width: 290px;
    max-width: 250px;
    min-width: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    border: 1px solid #bcbcbc;
    background-color: #fff;
    height: 300px;
}

.xl-card {
    height: 430px;
    justify-content: flex-start;
}

.p-bold-active {
    font-size: 14px;
    color: #ff5744;
    font-weight: bold;
}

.pricing-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    height: 70%;
}

.pricing-arrow-image {
    position: absolute;
    height: 200px;
    bottom: -190px;
    transform: scale(1, -1) rotateZ(-40deg);
    left: 30px;
    z-index: 99;
}

.z-index-2 {
    z-index: 2;
}

.pricing-bunner {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    width: 80%;
    max-width: 1380px;
}

.footer-text {
    font-family: "AvenirLTStd-Book" !important;
    cursor: pointer;
}

.footer-text:hover {
    color: var(--action-button-color);
}

.footer-link {
    cursor: pointer;
}

.hero-text {
    color: var(--action-button-color);
}

.feature-width {
    max-width: 300px;
}

.result-width-img {
    max-width: 100px;
}

.result-text-width {
    max-width: 250px;
}

.price-text {
    font-family: "AvenirLTStd-Black" !important;
    font-size: 40px !important;
}
.small-usd {
    font-size: 20px !important;
    color: var(--action-button-color);
    font-weight: bold;
}
.fz-16 {
    font-size: 16px !important;
    color: #4d4d4d;
}

.pricing-headline {
    font-size: 25px;
}

.light-text {
    font-family: "AvenirLTStd-Book" !important;
    max-width: 85%;
    z-index: 2;
}

.price-text-bold {
    font-family: "AvenirLTStd-Roman" !important;
    font-weight: bold !important;
}

.price-text-small {
    font-size: 12px;
}

.bold-roman {
    font-weight: bold !important;
}

.text-maximum {
    width: max-content;
}

.fz-14 {
    font-size: 14px;
}

.nav-bg {
    background-color: #fbfbfb;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.first-block-lending {
    height: calc(100vh - 73px);
}

.first-block-lending-z {
    z-index: 2;
}

.first-block-lending-z h1 {
    font-size: 3rem;
}

.lp_button {
    border-radius: 0.25rem;
    background-color: #ff5744;
    border: none;
    color: #fff;
    font-family: "AvenirLTStd-Black" !important;
    font-size: 18px;
    line-height: 20px;
    height: 50px;
    width: 220px;
    padding: 0 !important;
    text-transform: uppercase;
}

.lp-xl {
    padding-top: 0.7rem !important;
    padding-bottom: 0.5rem !important;
}

.nav-list a {
    color: #142c51 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.ml-10 {
    margin-left: 10px;
}

.section-name {
    font-size: 48px !important;
    line-height: 60px !important;
    color: #4d4d4d !important;
    text-align: center !important;
    margin-bottom: 50px;
}

.basis-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1920px !important;
}

.first-block-basis {
    flex-basis: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.story-title {
    font-size: 48px;
    line-height: 60px;
    text-align: left;
    width: 80%;
}

.story-text {
    font-family: "AvenirLTStd-Book" !important;
    width: 80%;
    font-size: 16px;
    line-height: 30px;
}

.story-image {
    width: 90%;
}

.second-block-basis {
    flex-basis: 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.welcome-bg {
    background-color: #ff5744;
    color: #fff;
    height: 675px;
}

.welcome-title {
    color: #fff;
    font-size: 48px;
    line-height: 60px;
}

.w-80 {
    width: 80%;
}

.py-10 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pl-bg {
    background-color: rgba(255, 87, 68, 4%);
}

.pl-section,
.uk-shipping-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1920px;
    flex-direction: column;
}

.guarantee-bg {
    background-color: #ff5744;
    color: #fff;
    min-height: 675px;
}

.guarantee-first-title {
    font-size: 46px;
    color: #fff;
}

.guarantee-second-title {
    font-size: 110px;
    color: #fff;
}

.guarantee-max-width {
    max-width: 980px;
}

.bold {
    font-family: "AvenirLTStd-Black" !important;
    font-weight: bold;
}

.save-money-title {
    color: #4d4d4d !important;
}

.save-money-block {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.line {
    width: 95%;
    border-top: 1px solid #c6c6c6;
}

.case-study {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.case-study-image {
    max-width: 251px;
    padding-right: 20px;
}

.case-study-title {
    font-size: 30px;
    color: #4d4d4d;
    font-family: "AvenirLTStd-Black";
}

.case-study-numbers {
    color: #fd5642;
    font-size: 50px;
}

.how-work-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.how-card {
    flex-basis: 25%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: rgba(255, 87, 68, 5%);
    position: relative;
    margin: 10px;
    max-width: 300px;
    min-width: 300px;
    min-height: 450px;
    margin-top: 100px;
}

.how-card-image-container {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
}

.how-card-image {
    width: 200px;
}
.small-image {
    width: 140px;
}
.pricing-bunner-content div {
    padding-top: 5px;
}

.how-card-title {
    color: #ff5744;
}

.how-card-desc {
    line-height: 30px;
    font-size: 16px;
    color: #4d4d4d;
}
.how-card-subtitle {
    line-height: 30px;
    font-size: 16px;
    font-weight: 900;
    color: #4d4d4d;
}

.why-sku-image-container {
    border-radius: 50%;
    background-color: rgba(255, 87, 68, 28%);
    max-width: 866px;
    max-height: 866px;
    width: 90%;
    height: 45vw;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.why-sku-image {
    width: 90%;
    margin-bottom: -6%;
    margin-right: -6%;
}

.baner-container {
    background-color: #ff5744;
    min-height: 456px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: -100px;
}

.baner-title {
    font-size: 48px;
    line-height: 46px;
    color: #fff;
}

.lp_button_light {
    background: #ffffff;
    border-radius: 5px;
    height: 79px;
    width: 508px;
    border: none;
    color: #000;
    font-family: "AvenirLTStd-Black" !important;
    font-size: 22px;
    line-height: 46px;
    text-transform: uppercase;
}

.footer-bg {
    background-color: #2b3044;
    min-height: 552px;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    z-index: 999;
    color: #fff;
    flex-wrap: wrap;
}

.footer-container-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    max-width: 25%;
    flex-basis: 25%;
}

.footer-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    display: flex;
}

.footer-icon {
    width: 25px;
    height: 25px;
}

.footer-contacts-container {
    display: flex;
    justify-content: space-evenly;
    padding-left: 30px;
    padding-top: 10px;
}

.footer-contacts-container p {
    width: 225px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    flex: 100%;
    max-width: 15%;
    flex-basis: 15%;
    margin-top: 35px;
    margin-left: 30px;
    /* border: 1px solid #fff; */
}

.footer-title {
    font-size: 28px;
    line-height: 34px;
    color: #fff;
}

.footer-list {
    padding-top: 30px !important;
    list-style: none;
    color: rgb(182, 182, 182);
}

.footer-item {
    font-size: 18px;
    color: #bbbec7;
    line-height: 35px;
}

.footer-item a {
    color: #bbbec7 !important;
}

.contact-block {
    width: 37px;
    height: 37px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.copyright-line {
    border-top: 1px solid rgba(255, 255, 255, 0.19);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
.terms-container {
    max-width: 1920px;
    padding: 30px;
}

.max-w-890px {
    max-width: 890px;
}
.fz-18px {
    font-size: 18px;
}
.fz-13px {
    font-size: 13px;
}
.fz-35px {
    font-size: 35px !important;
}
.block-w-responsive {
    width: 70%;
}
.container-in-td {
    border-left: 2px solid rgb(222, 222, 222);
    border-right: 2px solid rgb(222, 222, 222);
    background-color: rgb(249, 249, 249);
}
.top-border {
    border-top: 2px solid rgb(222, 222, 222);
}
.bottom-border {
    border-bottom: 2px solid rgb(222, 222, 222);
}

.main-table-wrapper {
    max-width: 1200px;
}
.header-col {
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
}
.header-left-col {
    max-width: 305px;
}
.w-30 {
    width: 30%;
}
.vs-position {
    position: absolute;
    top: -10px;
}
.table-header-mobile {
    position: sticky;
    top: 0;
    background-color: #fafbfe;
}
.max-w-260px {
    max-width: 260px;
}
.avenir-book-400 {
    font-family: "AvenirLTStd-Book" !important;
    font-weight: 400;
}
.avenir-book-500 {
    font-family: "AvenirLTStd-Book" !important;
    font-weight: 500;
}
.w-55 {
    width: 55%;
}

.pricing-card-container {
    width: 310px;
    display: flex;
    flex-direction: column;
    margin: 10px 12px;
    background-color: #fff;
    height: 520px;
}

.pricing-card-color-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fd5642;
    padding: 18px 0px;
    border: 1px solid #fd5642;
}

.pricing-card-color-title-header {
    color: white;
    line-height: 1;
    margin: 0;
}

.pricing-card-color-title-subheader {
    font-family: "AvenirLTStd-Roman";
    font-size: 14px;
    color: white;
    line-height: 1;
    margin: 0;
}

.pricing-simple-card-info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px 46px;
    border: 1px solid #bcbcbc;
    border-top: none;
}

.pricing-simple-card-info-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pricing-simple-card-info-name {
    font-family: "AvenirLTStd-Black";
    font-size: 16px;
    color: #4d4d4d;
    margin: 0;
    text-align: center;
}

.pricing-simple-card-info-price {
    color: #fd5642;
    margin: 0;
}

.pricing-simple-card-info-currency {
    font-family: "AvenirLTStd-Black";
    font-size: 16px;
    color: #fd5642;
}

.pricing-simple-card-info-comment {
    font-family: "AvenirLTStd-Roman";
    font-size: 14px;
    color: #4d4d4d;
    margin: 0;
    text-align: center;
    text-wrap: balance;
    line-height: 1.2;
}

.pricing-simple-card-info-line {
    width: 90%;
    border-top: 2px solid #d3d3d3;
}

.pricing-special-card-info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 26px;
    border: 1px solid #bcbcbc;
    border-top: none;
}

.pricing-special-card-info-description {
    font-family: "AvenirLTStd-Black";
    font-size: 16px;
    color: #4d4d4d;
    margin: 0;
    text-align: center;
    line-height: 1.1;
}

.pricing-special-card-info-line {
    width: calc(90% - 40px);
    border-top: 2px solid #d3d3d3;
}

.pricing-special-card-info-feature {
    font-family: "AvenirLTStd-Roman";
    font-size: 14px;
    color: #4d4d4d;
    margin: 0;
    margin-top: 4px;
    text-align: center;
    text-wrap: balance;
}

.pricing-special-card-contact-button {
    border-radius: 0.25rem;
    background-color: #ff5744;
    border: none;
    color: #fff;
    font-family: "AvenirLTStd-Black" !important;
    font-size: 18px;
    line-height: 20px;
    height: 40px;
    width: 100%;
    padding: 0 !important;
    text-transform: uppercase;
}

.pricing-rates-card-container {
    width: 310px;
    display: flex;
    flex-direction: column;
    margin: 10px 12px;
    background-color: #fff;
    height: 520px;
    border: 1px solid #bcbcbc;
}

.pricing-rates-card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 15px 0px;
    border-bottom: 1px solid #bcbcbc;
}

.pricing-rates-card-title-header {
    line-height: 1;
    margin: 0;
}

.pricing-rates-card-info-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 46px;
}

.pricing-rates-card-info-feature {
    font-family: "AvenirLTStd-Black";
    font-size: 16px;
    color: #4d4d4d;
    margin: 0;
    text-align: center;
    text-wrap: balance;
}

.pricing-rates-card-info-calculator {
    font-family: "AvenirLTStd-Black";
    font-size: 16px;
    color: #ff5744;
    margin: 0;
    text-align: center;
    text-wrap: balance;
    margin-bottom: 8px;
}

.pricing-rates-card-info-line {
    width: 90%;
    border-top: 2px solid #d3d3d3;
    position: relative;
}

.pricing-line-carton-img-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 0px 5px;
}

.pricing-line-carton-img {
    height: 60px;
}

.pricing-rates-carrier-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
    position: relative;
    color: #4d4d4d;
}

.pricing-rates-carrier-icon {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.contact-sales-modal > .modal-content {
    max-width: 400px;
    min-height: 350px;
}

.contact-sales-modal-header {
    padding: 16px 16px 0px 16px !important;
    border: none !important;
}

.contact-sales-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 280px;
    padding-bottom: 12px;
}

.contact-sales-done-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 280px;
}

.contact-sales-modal-question-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
    width: 100%;
    min-height: 200px;
    height: 100%;
}

.contact-sales-modal-error-message {
    font-size: 12px;
    position: absolute;
    color: #e20338;
    margin: 0;
    margin-top: 0.1rem;
    left: 50%;
    transform: translate(-50%, 0);
}

.contact-sales-modal-button {
    padding: 8px 32px !important;
    min-width: 240px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.contact-sales-modal-button-text {
    font-size: 14px !important;
    line-height: 1 !important;
    color: #fff !important;
    margin: 0 !important;
}

.connect-with-us-image {
    width: 400px;
    height: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.connect-with-us-image-container {
    display: flex;
    align-items: end;
}
.connect-with-us-section {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ff5744;
    color: #fff;
    min-height: 300px;
}

.connect-with-us-content {
    gap: 20px;
    padding: 20px 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
}

.connect-with-us-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
}

.connect-with-us-btn {
    color: #ff5744;
    background-color: #fff;
    border-radius: 0.25rem;
    border: none;
    font-family: "AvenirLTStd-Black" !important;
    font-size: 18px;
    line-height: 20px;
    height: 50px;
    width: 220px;
    padding: 0 !important;
    text-transform: uppercase;
}
.connect-with-us-second-title {
    font-family: "AvenirLTStd-Black" !important;
    font-size: 48px;
    line-height: 48px;
    text-transform: capitalize;
    font-weight: 500;
    color: #fff;
}

.connect-with-us-text {
    font-family: "AvenirLTStd-Roman" !important;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    width: 80%;
    margin: 0;
}

.own-ff-section-wrapper {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 1325px;
}

.own-ff-section-label {
    color: #fd5642;
    text-wrap: balance;
    max-width: 550px;
    font-size: 48px;
    line-height: 60px;
}

.own-ff-section-desc-paragraph {
    font-family: "AvenirLTStd-Book";
    line-height: 30px;
}

.own-ff-section-img-container {
    margin-left: 32px;
    width: 700px;
}

.own-ff-section-img {
    width: inherit;
}

@media (max-width: 870px) {
    .connect-with-us-image {
        display: none;
    }
}

@media (max-width: 1100px) {
    .connect-with-us-section {
        justify-content: center;
    }
    .connect-with-us-content {
        gap: 20px;
        padding-right: 70px;
        padding: 50px 0 50px;
        justify-content: center;
        flex-direction: column;
        justify-content: center;
    }

    .connect-with-us-text-wrapper {
        width: 75%;
    }

    .connect-with-us-text {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }

    .connect-with-us-second-title {
        text-align: center;
        font-size: 36px;
        line-height: 40px;
    }
}

@media (max-width: 800px) {
    .connect-with-us-image {
        display: none;
    }

    .connect-with-us-content {
        padding: 50px 0 50px;
        justify-content: center;
        flex-direction: column;
    }

    .connect-with-us-text {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }

    .connect-with-us-second-title {
        text-align: center;
        font-size: 36px;
        line-height: 36px;
    }
}

@media screen and (max-width: 1210px) {
    .footer {
        justify-content: center;
    }

    .footer-container {
        max-width: 45%;
        flex-basis: 45%;
        align-self: center;
        margin-top: 150px;
    }

    .footer-container-contacts {
        max-width: 45%;
        flex-basis: 45%;
        margin-top: 150px;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .footer-container {
        max-width: 100%;
        flex-basis: 100%;
        margin-top: 50px;
    }

    .footer-container-contacts {
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width: 1925px) {
    .max-width-1920 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .logo-width {
        width: 267px;
    }
}

@media (max-width: 1680px) {
    .lp_button {
        border-radius: 0.25rem;
        background-color: #ff5744;
        border: none;
        color: #fff;
        font-family: "AvenirLTStd-Black" !important;
        font-size: 18px;
        line-height: 20px;
        height: 50px;
        width: 220px;
        padding: 0 !important;
    }

    .lp-xl {
        padding-top: 0.7rem !important;
        padding-bottom: 0.5rem !important;
    }

    .hero-img {
        margin-top: 10px;
    }
    .hero-title {
        font-size: 45px;
        line-height: 55px;
    }
    .hero-desc {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .case-study-title {
        font-size: 27px;
    }
    .case-study-numbers {
        font-size: 45px;
    }
}

@media (max-width: 1350px) {
    .lp_button {
        border-radius: 0.25rem;
        background-color: #ff5744;
        border: none;
        color: #fff;
        font-family: "AvenirLTStd-Black" !important;
        font-size: 18px;
        line-height: 20px;
        height: 50px;
        width: 220px;
        padding: 0 !important;
    }

    .lp-xl {
        padding-top: 0.7rem !important;
        padding-bottom: 0.5rem !important;
    }
    .hero-title {
        font-size: 40px;
        line-height: 50px;
    }
    .hero-desc {
        font-size: 19px !important;
        line-height: 26px !important;
    }
    .case-study-title {
        font-size: 25px;
    }
    .case-study-numbers {
        font-size: 40px;
    }
    .pricing-card {
        max-width: 300px;
        min-width: 300px;
        height: 350px;
    }
    .xl-card {
        height: 430px;
    }

    .own-ff-section-img-container {
        margin-left: 16px;
        width: 45vw;
    }
}

@media (max-width: 1280px) {
    .lp_button {
        border-radius: 0.25rem;
        background-color: #ff5744;
        border: none;
        color: #fff;
        font-family: "AvenirLTStd-Black" !important;
        font-size: 18px;
        line-height: 20px;
        height: 50px;
        width: 220px;
        padding: 0 !important;
    }

    .lp-xl {
        padding-top: 0.7rem !important;
        padding-bottom: 0.5rem !important;
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .pricing-bunner {
        flex-direction: column !important;
    }
    .save-money-img {
        width: 15% !important;
    }
    .case-study-image {
        width: 100% !important;
    }
    .case-study-title {
        font-size: 20px;
    }

    .case-study-numbers {
        font-size: 38px;
    }
    .pricing-bunner-button {
        justify-content: center !important;
    }

    .pricing-bunner-content {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .pricing-bunner-content div {
        margin-top: 20px;
    }
    .hero-title {
        font-size: 33px;
        line-height: 43px;
    }
    .hero-desc {
        font-size: 15px !important;
        line-height: 24px !important;
    }
    .baner-title {
        font-size: 40px !important;
    }
}

@media (max-width: 990px) {
    .lp_button {
        border-radius: 0.25rem;
        background-color: #ff5744;
        border: none;
        color: #fff;
        font-family: "AvenirLTStd-Black" !important;
        font-size: 18px;
        line-height: 20px;
        height: 50px;
        width: 220px;
        padding: 0 !important;
        margin-top: 20px;
    }

    .lp-xl {
        padding-top: 0.7rem !important;
        padding-bottom: 0.5rem !important;
        margin-left: 0 !important;
        padding-right: 70px !important;
        padding-left: 70px !important;
        margin-top: 20px;
    }
    .why-sku-image {
        margin-bottom: 0;
        margin-right: 0;
    }
    .story-text {
        width: 100% !important;
        padding-left: 40px;
        padding: 40px;
        padding-top: 0;
    }
    .story-text-mobile-p {
        width: 100% !important;
        padding-left: 40px;
        padding-bottom: 13px;
        padding-top: 0;
        margin-bottom: 0 !important;
    }
    .how-card {
        margin-top: 90px;
        min-height: 450px;
    }
    .story-padding {
        padding: 0 !important;
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
    .case-study {
        flex-direction: column !important;
        padding-bottom: 50px !important;
    }
    .save-money-content {
        width: 100% !important;
        padding: 5px;
    }
    .save-money-img {
        width: 80% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .case-study-image {
        width: 100% !important;
    }

    .case-study-title {
        font-size: 27px;
    }

    .case-study-numbers {
        font-size: 40px;
    }
    .story-buton-container {
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
    }

    .first-block-basis {
        flex-basis: 100%;
        max-width: 100%;
        align-items: center;
    }

    .second-block-basis {
        flex-basis: 100%;
        max-width: 100%;
        height: 50%;
    }
    .mobile-align-items {
        align-items: initial;
    }
    .mobile-mb-30 {
        margin-bottom: 60px;
    }
    .mobile-mb-30 img {
        width: 100%;
    }
    .why-sku-image-container {
        height: 90vw;
    }

    .baner-container {
        min-height: 350px;
    }

    .lp_button_light {
        width: 250px;
    }

    .baner-title {
        font-size: 36px;
        padding: 20px;
        text-align: center;
    }

    .accordion {
        width: 80% !important;
    }

    .welcome-title {
        text-align: center;
        font-size: 36px;
    }

    .hero-img {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .hero-desc {
        max-width: 100%;
        padding: 20px;
    }

    .section-name {
        font-size: 36px !important;
        margin-bottom: 20px;
        margin-top: 0 !important;
        width: 90%;
        line-height: 40px !important;
    }
    .story-title {
        font-size: 36px !important;
        line-height: 45px !important;
        margin-bottom: 25px !important;
        margin-top: 0 !important;
    }
    #fees {
        padding-bottom: 0 !important;
    }
    .guarantee-first-title {
        font-size: 22px;
        margin-top: 1rem;
        padding-top: 40px;
    }
    .pricing-cards {
        margin-top: 10px !important;
    }
    .guarantee-second-title {
        font-size: 52px;
    }

    .guarantee-max-width {
        padding-top: 0 !important;
    }
    .guarentee-middle-text {
        padding: 0 !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .nav-list a {
        text-align: center;
        font-size: 25px !important;
    }

    .navbar-nav {
        justify-content: center;
        align-items: center;
    }

    .save-money-numbers {
        align-items: center;
    }

    .save-money-numbers div {
        padding: 0 !important;
        width: 70% !important;
        max-width: 250px;
    }

    .menu-icon {
        display: block;
    }

    .logo-width {
        width: 267px;
    }
    .title-position {
        margin-top: 10px;
    }
    .py-10 {
        padding-top: 45px !important;
        padding-bottom: 10px !important;
    }
    .mb-3rem {
        margin-bottom: 3rem;
    }
    .how-card-subtitle {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .own-ff-section-wrapper {
        flex-direction: column-reverse;
    }

    .own-ff-section-label {
        text-align: center;
        text-wrap: balance;
        max-width: unset;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 24px;
    }

    .own-ff-section-desc-paragraph {
        text-align: center;
        text-wrap: balance;
        line-height: 24px;
    }

    .own-ff-section-img-container {
        display: flex;
        justify-content: center;
        width: 80%;
        margin-left: 0px;
        margin-bottom: 32px;
    }
}

@media (max-width: 660px) {
    .save-money-numbers {
        flex-direction: column !important;
        align-items: center;
    }
    .mobile-margin-top {
        margin-top: 50px !important;
    }
    .block-w-responsive {
        width: 75%;
    }

    .own-ff-section-img-container {
        width: 100%;
    }
}
