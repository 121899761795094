body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
    font-family: "AvenirLTStd-Roman";
    src:
        local("AvenirLTStd-Roman"),
        url(./assets/fonts/AvenirLTStd-Roman.otf) format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "AvenirLTStd-Book";
    src:
        local("AvenirLTStd-Book"),
        url(./assets/fonts/AvenirLTStd-Book.otf) format("opentype");
    font-display: swap;
}
@font-face {
    font-family: "AvenirLTStd-Black";
    src:
        local("AvenirLTStd-Black"),
        url(./assets/fonts/AvenirLTStd-Black.otf) format("opentype");
    font-display: swap;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
